
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SwitchInput from '@/components/Form/SwitchInput.vue';
  import {ChargingStationMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
  import ChargingStationService from "@/services/ChargingStationService";
  import {IApiChargingStationProduct} from "@/store/interfaces/RootState";

	@Component({
		name: 'ChargingStationSubStep',
		components: {SwitchInput, SmartCustomSelect}
	})
	export default class ChargingStationSubStep extends SubStep
	{

    private get chargingStationProducts(): IApiChargingStationProduct[]
    {
      return ChargingStationService.getFilteredChargingStationProducts();
    }

    private onChargingStationSelected(value: IApiChargingStationProduct)
		{
			this.$store.commit(ChargingStationMutation.SetChargingStation, value);

			this.$emit('next-step');
		}
	}


	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {Route} from 'vue-router';
	import {routes} from '@/router';
	import StepService from '@/services/StepService';
	import Popover from '@/components/Popover.vue';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import ICustomRouteConfig from '@/router/interfaces/CustomRouteConfig';
	import Step from '@/enums/Step';

	@Component({
		name: 'Navigation',
		components: {Popover}
	})
	export default class Navigation extends Vue
	{
		private readonly Step = Step;
		private readonly StepService = StepService;
		private readonly routes = routes;

		private navigationElement: HTMLElement;

		private mounted()
		{
			EventBus.$on(GlobalEvent.RouteChanged, this.onRouteChanged);

			/* eslint-disable */
			this.navigationElement = this.$refs.navigation as HTMLElement;
			/* eslint-enable */
		}

		private onRouteChanged(newRoute: Route | ICustomRouteConfig)
		{
			if (this.$store.state.windowWidth >= 1000) return;

			const navItem = document.getElementById(`nav-item-${newRoute.meta.stepId}`);
			const navItemOffsetLeft = navItem.offsetLeft;

			//scroll navigation bar horizontally so the current route (nav item) is in the center of the navigation bar
			this.navigationElement.scrollLeft = navItemOffsetLeft - navItem.scrollWidth;
		}
	}

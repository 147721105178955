import {extend} from 'vee-validate';
import {email, regex} from 'vee-validate/dist/rules';
import TranslationService from '@/services/TranslationService';

extend('required', {
	validate(value) {
		return {
			required: true,
			valid: ['', null, undefined].indexOf(value) === -1
		}
	},
	computesRequired: true,
	get message()
	{
		return TranslationService.translate('This is a mandatory field');
	}
});

extend('email', {
	...email,
	get message()
	{
		return TranslationService.translate('This is not a valid e-mail address');
	}
});

extend('regex', {
	...regex,
	get message()
	{
		return TranslationService.translate('This is not a valid phone number, please use the country code (e.g. +41...)');
	}
});

extend('accepted', {
	validate(value)
	{
		return {
			required: true,
			valid: value === true
		}
	},
	get message()
	{
		return TranslationService.translate('You need to accept');
	}
});

	import Vue from 'vue';
	import Component from 'vue-class-component';
	import Step from '@/enums/Step';
	import SubStep from '@/enums/SubStep';
	import {mapState} from 'vuex';
	import IRootState, {
		IApiChargingStationProduct,
		ICouponDefinition,
		IInstallationProduct
	} from '@/store/interfaces/RootState';
	import CalculationService from '@/services/CalculationService';
	import {forEach} from 'lodash';
	import FurtherAction from '@/enums/FurtherAction';
	import Installation from '@/enums/Installation';
	import {APIAction} from '@/store/actions';
	import {CalculationMutation} from '@/store/mutations';

	@Component({
		name: 'CalculationTable',
		computed: {
			...mapState({
				chargingStationProduct: (state: IRootState) => state.steps[Step.ChargingStation][SubStep.ChargingStation]
			})
		}
	})
	export default class CalculationTable extends Vue
	{
		private readonly Step = Step;
		private readonly SubStep = SubStep;

		private detailsVisible = {
			'installation': false
		};

		private chargingStationProduct: IApiChargingStationProduct;

		private calculationService: CalculationService;

		private showInvalidCouponMessage = false;

		constructor()
		{
			super();

			this.calculationService = new CalculationService();
		}

		private mounted()
		{
			this.$smoothReflow({
				el: this.$refs.installationDetails as HTMLElement
			});
		}

		private showHomeCheck(): boolean
		{
			return this.$store.state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction == FurtherAction.HomeCheckOrdered ||
				this.isHomeCheckIncluded();
		}

		private isHomeCheckIncluded(): boolean
		{
			return this.calculationService.isHomeCheckIncluded();
		}

		private getHomeCheckCost(): number
		{
			return this.calculationService.getHomeCheckCost();
		}

		private getInstallationCost(): number
		{
			return this.calculationService.getInstallationCost();
		}

		private getInstallationDescriptions(): string[]
		{

			const installationDescriptions = [];

			forEach(this.calculationService.getInstallationProducts(), (item: IInstallationProduct) =>
			{
				if (item.product)
				{
					installationDescriptions.push(item.amount + ' x ' + item.product.name);
				}
			});

			return installationDescriptions;
		}

		private showCharger(): boolean
		{
			return !this.$store.state.steps[Step.ExistingCharger][SubStep.ExistingChargerAvailable] &&
				!!this.chargingStationProduct &&
				!!this.chargingStationProduct.chargingStation;
		}

		private getChargerCost(): number
		{
			if (this.showCharger())
			{
				return this.calculationService.getChargerCost(this.chargingStationProduct.chargingStation);
			}

			return 0;
		}

		private showPillar(): boolean
		{
			return !this.$store.state.steps[Step.ExistingCharger][SubStep.ExistingChargerAvailable] &&
				!!this.chargingStationProduct &&
				!!this.chargingStationProduct.pillar &&
				this.$store.state.steps[Step.Installation][SubStep.Installation] == Installation.NoMountingOptions;
		}

		private getPillarCost(): number
		{
			if (this.showPillar())
			{
				return this.calculationService.getPillarCost(this.chargingStationProduct.pillar);
			}

			return 0;
		}

		private showShippingCost(): boolean
		{
			return this.getShippingCost() > 0;
		}

		private getShippingCost(): number
		{
			if (this.chargingStationProduct)
			{
				return this.calculationService.getShippingCost(this.chargingStationProduct);
			}

			return 0;
		}

		private get couponCode(): string
		{
			return this.$store.state.steps[Step.Calculation][SubStep.CalculationSummary].couponCode;
		}

		private set couponCode(couponCode: string)
		{
			this.$store.commit(CalculationMutation.SetCouponCode, couponCode);
		}

		private get couponDefinition(): ICouponDefinition
		{
			return this.$store.state.steps[Step.Calculation][SubStep.CouponDefinition];
		}

		private get isValidCoupon(): boolean
		{
			return this.couponCode && !!this.couponDefinition;
		}

		private showCouponCode(): boolean
		{
			return this.$store.state.api.config.showCouponCode;
		}

		private getCouponCost(): number
		{
			if (this.showCouponCode())
			{
				const chargingStation = (this.chargingStationProduct && this.chargingStationProduct.chargingStation) ? this.chargingStationProduct.chargingStation : null;
				const pillar = (this.chargingStationProduct && this.chargingStationProduct.pillar) ? this.chargingStationProduct.pillar : null;
				return this.calculationService.getCouponCost(chargingStation, pillar);
			}

			return 0;
		}

		private async onValidateCouponClicked(): Promise<void>
		{
			this.showInvalidCouponMessage = false;

			await this.$store.dispatch(APIAction.ValidateCoupon).then(() =>
			{
				if (!this.isValidCoupon)
				{
					this.showInvalidCouponMessage = true;
				}
			});
		}

		private onClearCouponClicked(): void
		{
			this.$store.commit(CalculationMutation.SetCouponCode, null);
		}

		//region home check table
		private getHomeCheckCouponCost(): number
		{
			if (this.showCouponCode())
			{
				return this.calculationService.getHomeCheckCouponCost();
			}

			return 0;
		}

		private getTotalHomeCheckCost(): number
		{
			let totalCost = 0;

			//home check
			totalCost += this.getHomeCheckCost();

			//coupon
			totalCost += this.getHomeCheckCouponCost();

			return totalCost;
		}

		private getHomeCheckVat(): number
		{
			if (this.calculationService.isIncludingVat())
			{
				return (this.getTotalHomeCheckCost() / 100) * this.getHomeCheckVatPercent();
			}

			return this.getTotalHomeCheckCost() * (this.getHomeCheckVatPercent() / 100);
		}

		private getHomeCheckVatPercent(): number
		{
			return this.$store.state.api.config.taxRate;
		}

		private getTotalHomeCheckCostIncludingVat(): number
		{
			let totalCost = this.getTotalHomeCheckCost();

			if (!this.calculationService.isIncludingVat())
			{
				totalCost += this.getHomeCheckVat();
			}

			//round to 5 Rappen (it's a "money" integer!)
			totalCost = Math.round(totalCost / 5) * 5;

			return totalCost;
		}

		//end region home check

		private getTotalCost(): number
		{
			let totalCost = 0;

			//installation
			totalCost += this.getInstallationCost();

			//charger
			totalCost += this.getChargerCost();

			//pillar
			totalCost += this.getPillarCost();

			//shipping
			totalCost += this.getShippingCost();

			//coupon
			totalCost += this.getCouponCost();

			return totalCost;
		}

		private getVat(): number
		{
			if (this.calculationService.isIncludingVat())
			{
				return (this.getTotalCost() / 100) * this.getVatPercent();
			}

			return this.getTotalCost() * (this.getVatPercent() / 100);
		}

		private getVatPercent(): number
		{
			return this.$store.state.api.config.taxRate;
		}

		private getTotalCostIncludingVat(): number
		{
			let totalCost = this.getTotalCost();

			if (!this.calculationService.isIncludingVat())
			{
				totalCost += this.getVat();
			}

			//round to 5 Rappen (it's a "money" integer!)
			totalCost = Math.round(totalCost / 5) * 5;

			return totalCost;
		}

		//endregion
	}

enum FilterOperator
{
	InArray = 'in-array',
	Contains = 'contains',
	AllOf = 'ao',
	Equal = 'eq',
	NotEqual = '!eq',
	GreaterThan = 'gt',
	LowerThan = 'lt',
	GreaterThanOrEqual = 'gte',
	LowerThanOrEqual = 'lte',
	IsNull = 'is-null',
	NotNull = 'not-null',
	Custom = 'custom'
}

export default FilterOperator;

	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {FusePanelMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'FusePanelSubStep',
		components: {SmartCustomSelect}
	})
	export default class FusePanelSubStep extends SubStep
	{
		private onFusePanelSelected(fusePanel: number)
		{
			this.$store.commit(FusePanelMutation.SetFusePanel, fusePanel);

			if (fusePanel)
			{
				this.$emit('next-sub-step');
			}
		}
	}

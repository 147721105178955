import {DirectiveBinding, DirectiveOptions} from 'vue/types/options';
import {VNode} from 'vue';
import Tooltip from '@/lib/Tooltip';
import {Placement} from '@popperjs/core';

const bindTooltip = (element: HTMLElement, content: string, placement?: Placement) => {
	if ((<any> element).tooltip)
	{
		return (<any> element).tooltip.update(content, placement);
	}

	const tooltip = new Tooltip(element, content, placement);
};

export default <DirectiveOptions> {
	bind: (element: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
		if (!binding.value)
		{
			return;
		}

		bindTooltip(element, binding.value, <Placement> binding.arg);
	},

	update: (element: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
		if (!binding.value)
		{
			return;
		}

		bindTooltip(element, binding.value, <Placement> binding.arg);
	},

	unbind: (element: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
		if ((<any> element).tooltip)
		{
			(<any> element).tooltip.destroy();
		}
	}
};

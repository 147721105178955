
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import {arrayPushUnique, formatFileSize} from '@/utils';
	import {StepMutation} from '@/store/mutations';
	import {isEqual, clone} from 'lodash';
	import ICustomRouteConfig from '@/router/interfaces/CustomRouteConfig';
	import Popover from "@/components/Popover.vue";

	@Component({
		name: 'FileUpload',
		components: {Popover}
	})
	export default class FileUpload extends Vue
	{
		private readonly formatFileSize = formatFileSize;

		private visible = false;
		private files: File[] = [];
		private fileDataUrls: Record<string, string> = {};

		private mounted()
		{
			EventBus.$on(GlobalEvent.LoadFiles, this.onLoadFiles);
			EventBus.$on(GlobalEvent.SaveFiles, this.onSaveFiles);
		}

		private onFileUploadClicked()
		{
			//eslint-disable-next-line
			const fileInput: HTMLInputElement = this.$refs.fileInput as HTMLInputElement;

			fileInput.files = null;
			fileInput.click();
		}

		private onFileInput(event)
		{
			for (const file of event.target.files)
			{
				arrayPushUnique(this.files, file);
				this.loadDataUrl(file);
			}
		}

		private onLoadFiles(step: ICustomRouteConfig)
		{
			if (!step.meta.allowFiles) return;

			this.files = clone(this.$store.state.steps[step.meta.stepId].files);
		}

		private onSaveFiles(step: ICustomRouteConfig)
		{
			if (!step.meta.allowFiles) return;

			this.$store.commit(StepMutation.SetFiles, {step, files: this.files});
			this.files = [];
		}

		private onFileDeleteClicked(file: File)
		{
			const index = this.files.findIndex((innerFile) => isEqual(innerFile, file));

			if (index >= 0)
			{
				this.files.splice(index, 1);
			}
		}

		private loadDataUrl(file: File)
		{
			const reader = new FileReader();
			reader.readAsDataURL(file);

			reader.onload = (event: any) => {
				//used file.name+file.size, because there could be multiple files with the same name
				Vue.set(this.fileDataUrls, file.name+file.size, event.target.result);
			};
		}
	}

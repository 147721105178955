enum Parking
{
	Underground = 1,
	Outdoor = 2,
	Distant = 3,
	SingleGarage = 4,
	DoubleGarage = 5,
	Carport = 6,
	Public = 7
}

export default Parking;

	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SubStep from '@/components/Steps/SubStep';
	import Modal from '@/components/Modal.vue';
	import {ChargingStationMutation} from '@/store/mutations';
  import Connectivity from "@/enums/Connectivity";

	@Component({
		name: 'ChargingStationConnectivitySubStep',
		components: {Modal, SmartCustomSelect}
	})
	export default class ChargingStationConnectivitySubStep extends SubStep
	{
		private onConnectivitySelected(connectivitiesSelected: number[])
		{
			const oldValue = this.$store.state.steps[this.Step.ChargingStation][this.SubStep.ChargingStationConnectivity];

			//4 is "no option"
			if (connectivitiesSelected.includes(Connectivity.None) && !oldValue.includes(Connectivity.None))
			{
				this.$store.commit(ChargingStationMutation.SetChargingStationConnectivity, [Connectivity.None]);
			}
			else
			{
				if (connectivitiesSelected.indexOf(Connectivity.None) >= 0)
				{
          connectivitiesSelected.splice(connectivitiesSelected.indexOf(Connectivity.None), 1);
				}

				this.$store.commit(ChargingStationMutation.SetChargingStationConnectivity, connectivitiesSelected);
			}
		}
	}

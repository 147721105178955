import {createPopper, Placement, Instance as PopperInstance} from '@popperjs/core';

class Tooltip
{
	private _element: HTMLElement;
	private _contentElement: HTMLElement;
	private _content: string;
	private _placement: Placement;
	private _tooltip: HTMLElement;
	private _popper: PopperInstance;

	constructor(element: HTMLElement, content: string, placement?: Placement)
	{
		this._element = element;
		this._content = content;
		this._placement = placement || 'top';

		this.show = this.show.bind(this);
		this.hide = this.hide.bind(this);

		(<any> this._element).tooltip = this;

		this.init();
	}

	public show()
	{
		this._tooltip.style.display = 'flex';

		requestAnimationFrame(() => {
			this._popper.update();
		});
	}

	public hide()
	{
		this._tooltip.style.display = 'none';
	}

	public update(content: string, placement: Placement)
	{
		this._content = content;
		this._contentElement.innerHTML = this._content;
		this._placement = placement || 'top';

		this._popper.setOptions({
			placement: this._placement
		});

		this._popper.update();
	}

	public destroy()
	{
		this._popper.destroy();

		document.body.removeChild(this._tooltip);
	}

	private init()
	{
		this._contentElement = document.createElement('div');
		this._contentElement.innerHTML = this._content;

		const arrow = document.createElement('div');
		arrow.setAttribute('x-arrow', '');

		this._tooltip = document.createElement('div');
		this._tooltip.classList.add('tooltip');
		this._tooltip.appendChild(this._contentElement);
		this._tooltip.appendChild(arrow);
		this._tooltip.style.display = 'none';

		this._popper = createPopper(this._element, this._tooltip, {
			placement: this._placement,
			modifiers: [
				{
					name: 'arrow',
					options: {
						element: arrow
					}
				},
				{
					name: 'offset',
					options: {
						offset: [0, 7]
					}
				}
			]
		});

		document.body.appendChild(this._tooltip);

		this.initListeners();
	}

	private initListeners()
	{
		this._element.addEventListener('mouseenter', this.show);
		this._element.addEventListener('mouseleave', this.hide);
	}
}

export default Tooltip;


	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import IInputOption from '@/components/Form/InputOption';
	import {InstallationMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'InstallationSubStep',
		components: {SmartCustomSelect}
	})
	export default class InstallationSubStep extends SubStep
	{
		private onInstallationSelected(installation)
		{
			this.$store.commit(InstallationMutation.SetInstallation, installation);

			if (installation)
			{
				this.$emit('next-step');
			}
		}
	}

enum GlobalEvent
{
	PreviousStep = 'previous-step',
	NextStep = 'next-step',
	LoadFiles = 'load-files',
	SaveFiles = 'save-files',
	LoadNotes = 'load-notes',
	SaveNotes = 'save-notes',
	ShowChargingStationError = 'show-charging-station-error',
	RouteChanged = 'route-changed',
	LocaleChanged = 'locale-changed',
	TranslationsLoaded = 'translations-loaded'
}

enum ModalEvent
{
	Show = 'modal-show',
	Hide = 'modal-hide'
}

export {
	GlobalEvent,
	ModalEvent
};
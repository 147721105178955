import {isEqual} from 'lodash';

const arrayPushUnique = (array: any[], value) => {
	if (!array.find((arrayElement) => isEqual(arrayElement, value)))
	{
		array.push(value);
	}
};

const formatFileSize = (size: number) => {
	const units = ['B', 'KB', 'MB', 'GB'];

	let i = 0;

	while (size > 1000)
	{
		size /= 1000;
		i++;
	}

	return size.toFixed(2) + units[i];
};


export {arrayPushUnique, formatFileSize};
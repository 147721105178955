
	import Component from 'vue-class-component';
	import {ParkingMutation} from '@/store/mutations';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'ParkingOwnershipSubStep',
		components: {SmartCustomSelect}
	})
	export default class ParkingOwnershipSubStep extends SubStep
	{
		private onOwnershipSelected(ownership)
		{
			this.$store.commit(ParkingMutation.SetParkingOwnership, ownership);

			EventBus.$emit(GlobalEvent.NextStep);
		}
	}

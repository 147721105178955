import store from '@/store';
import IInputOption from '@/components/Form/InputOption';
import Step from '@/enums/Step';
import SubStep from '@/enums/SubStep';
import TranslationService from '@/services/TranslationService';
import FusePanelOldImage from './assets/img/fuse-panel-old.jpg';
import FusePanelNewImage from './assets/img/fuse-panel-new.jpg';
import OutletT13Image from './assets/img/outlets/outlet-t13.png';
import OutletT25Image from './assets/img/outlets/outlet-t25.png';
import OutletCEE16230Image from './assets/img/outlets/outlet-cee16-230.png';
import OutletCEE16400Image from './assets/img/outlets/outlet-cee16-400.png';
import OutletCEE32400Image from './assets/img/outlets/outlet-cee-32-400.png';
import InstallationFacadeImage from './assets/img/installation/installation-facade.png';
import InstallationPillarImage from './assets/img/installation/installation-pillar.png';
import InstallationWoodImage from './assets/img/installation/installation-wood.png';
import InstallationBrickWallImage from './assets/img/installation/installation-brick-wall.png';
import InstallationNoneImage from './assets/img/installation/installation-none.png';
import ChargingStationSmartImage from './assets/img/charging-station-smart.png';
import Integration from "@/enums/Integration";
import Ownership from "@/enums/Ownership";
import Property from "@/enums/Property";
import Parking from "@/enums/Parking";
import Installation from "@/enums/Installation";
import SignalQuality from "@/enums/SignalQuality";
import FusePanel from "@/enums/FusePanel";
import Outlet from "@/enums/Outlet";
import Connectivity from "@/enums/Connectivity";

const stepOptions: Record<Step, Record<string, IInputOption[]> | IInputOption[]> = {
	[Step.Start]: {},
	[Step.Property]: {
		[SubStep.Property]: [
			{
				get label() {
					return TranslationService.translate('Single-family house');
				},
				icon: 'dhc/single-family-house',
				value: Property.SingleFamilyHouse
			},
			{
				get label()
				{
					return TranslationService.translate('Multi-family house');
				},
				icon: 'dhc/multi-family-house',
				value: Property.MultiFamilyHouse
			},
			{
				get label()
				{
					return TranslationService.translate('Apartment');
				},
				icon: 'dhc/apartment',
				value: Property.Apartment
			},
			{
				get label()
				{
					return TranslationService.translate('Company');
				},
				icon: 'dhc/company',
				value: Property.Company
			}
		],
		[SubStep.PropertyOwnership]: [
			{
				get label()
				{
					return TranslationService.translate('Owner');
				},
				icon: 'dhc/owner',
				value: Ownership.Owner
			},
			{
				get label()
				{
					return TranslationService.translate('Co-Owner');
				},
				icon: 'dhc/owner',
				value: Ownership.CoOwner
			},
			{
				get label()
				{
					return TranslationService.translate('Tenant');
				},
				icon: 'dhc/tenant',
				value: Ownership.Tenant
			},
			{
				get label()
				{
					return TranslationService.translate('Subtenant');
				},
				icon: 'dhc/subtenant',
				value: Ownership.Subtenant
			}
		]
	},
	[Step.Parking]: {
		[SubStep.Parking]: [
			{
				get label()
				{
					return TranslationService.translate('Underground');
				},
				icon: 'dhc/parking-underground',
				value: Parking.Underground
			},
			{
				get label()
				{
					return TranslationService.translate('Outdoor');
				},
				icon: 'dhc/parking-outdoor',
				value: Parking.Outdoor
			},
			{
				get label()
				{
					return TranslationService.translate('Distant');
				},
				icon: 'dhc/parking-distant',
				value: Parking.Distant
			},
			{
				get label()
				{
					return TranslationService.translate('Single garage');
				},
				icon: 'dhc/parking-single-garage',
				value: Parking.SingleGarage
			},
			{
				get label()
				{
					return TranslationService.translate('Double garage');
				},
				icon: 'dhc/parking-double-garage',
				value: Parking.DoubleGarage
			},
			{
				get label()
				{
					return TranslationService.translate('Carport');
				},
				icon: 'dhc/parking-carport',
				value: Parking.Carport
			},
			{
				get label()
				{
					return TranslationService.translate('Public');
				},
				icon: 'dhc/parking-public',
				value: Parking.Public
			}
		],
		[SubStep.ParkingOwnership]: [
			{
				get label()
				{
					return TranslationService.translate('Owner');
				},
				icon: 'dhc/owner',
				value: Ownership.Owner
			},
			{
				get label()
				{
					return TranslationService.translate('Co-Owner');
				},
				icon: 'dhc/owner',
				value: Ownership.CoOwner
			},
			{
				get label()
				{
					return TranslationService.translate('Tenant');
				},
				icon: 'dhc/tenant',
				value: Ownership.Tenant
			},
			{
				get label()
				{
					return TranslationService.translate('Subtenant');
				},
				icon: 'dhc/subtenant',
				value: Ownership.Subtenant
			}
		]
	},
	[Step.Installation]: {
		[SubStep.Installation]: [
			{
				get label()
				{
					return TranslationService.translate('Facade mounting');
				},
				image: InstallationFacadeImage,
				value: Installation.Facade
			},
			{
				get label()
				{
					return TranslationService.translate('Pillar mounting');
				},
				image: InstallationPillarImage,
				value: Installation.ExistingPillar
			},
			{
				get label()
				{
					return TranslationService.translate('Wood mounting');
				},
				image: InstallationWoodImage,
				value: Installation.Wood
			},
			{
				get label()
				{
					return TranslationService.translate('Concrete/brick wall');
				},
				image: InstallationBrickWallImage,
				value: Installation.ConcreteWall
			},
			{
				get label()
				{
					return TranslationService.translate('No mounting options');
				},
				image: InstallationNoneImage,
				value: Installation.NoMountingOptions
			}
		]
	},
	[Step.Integration]: {
		[SubStep.Integration]: [
			{
				get label()
				{
					return TranslationService.translate('Home automation');
				},
				faicon: ['fal', 'house-signal'],
				value: Integration.HomeAutomation
			},
			{
				get label()
				{
					return TranslationService.translate('Photovoltaics');
				},
				faicon: ['fal', 'solar-panel'],
				value: Integration.Photovoltaics
			},
			{
				get label()
				{
					return TranslationService.translate('Battery storage');
				},
				faicon: ['fal', 'car-battery'],
				value: Integration.BatteryStorage
			},
			{
				get label()
				{
					return TranslationService.translate('Charging process accountance');
				},
				faicon: ['fal', 'file-invoice-dollar'],
				value: Integration.ChargingProcessAccountance
			},
			{
				get label()
				{
					return TranslationService.translate('Charge only');
				},
				faicon: ['fal', 'charging-station'],
				value: Integration.NoIntegration
			}
		]
	},
	[Step.ChargingStation]: {
		[SubStep.ChargingStationIntelligent]: [
			{
				get label()
				{
					return TranslationService.translate('Yes');
				},
				image: ChargingStationSmartImage,
				value: true
			},
			{
				get label()
				{
					return TranslationService.translate('No');
				},
				faicon: ['fal', 'charging-station'],
				value: false,
				disabled: () => {
					return Array.isArray(store.state.steps[Step.Integration][SubStep.Integration]) && !store.state.steps[Step.Integration][SubStep.Integration].includes(Integration.NoIntegration);
				}
			}
		],
		[SubStep.ChargingStationConnectivity]: [
			{
				get label()
				{
					return TranslationService.translate('WiFi');
				},
				faicon: ['fal', 'wifi'],
				value: Connectivity.WiFi
			},
			{
				get label()
				{
					return TranslationService.translate('LAN');
				},
				faicon: ['fal', 'network-wired'],
				value: Connectivity.LAN
			},
			{
				get label()
				{
					return TranslationService.translate('Mobile');
				},
				faicon: ['fal', 'mobile-alt'],
				value: Connectivity.Mobile
			},
			{
				get label()
				{
					return TranslationService.translate('No connectivity')
				},
				faicon: ['fal', 'times-circle'],
				value: Connectivity.None
			}
		],
		[SubStep.ChargingStationSignalQualityWifi]: [
			{
				get label()
				{
					return TranslationService.translate('Good');
				},
				faicon: ['fal', 'wifi'],
				value: SignalQuality.Good
			},
			{
				get label()
				{
					return TranslationService.translate('Medium');
				},
				faicon: ['fal', 'wifi-2'],
				value: SignalQuality.Medium
			},
			{
				get label()
				{
					return TranslationService.translate('Bad');
				},
				faicon: ['fal', 'wifi-1'],
				value: SignalQuality.Bad
			}
		],
		[SubStep.ChargingStationSignalQualityGprs]: [
			{
				get label()
				{
					return TranslationService.translate('Good');
				},
				faicon: ['fal', 'wifi'],
				value: SignalQuality.Good
			},
			{
				get label()
				{
					return TranslationService.translate('Medium');
				},
				faicon: ['fal', 'wifi-2'],
				value: SignalQuality.Medium
			},
			{
				get label()
				{
					return TranslationService.translate('Bad');
				},
				faicon: ['fal', 'wifi-1'],
				value: SignalQuality.Bad
			}
		]
	},
	[Step.ExistingOutlets]: {
		[SubStep.ExistingOutletsAvailable]: [
			{
				get label()
				{
					return TranslationService.translate('Yes');
				},
				faicon: ['fal', 'check-circle'],
				value: true
			},
			{
				get label()
				{
					return TranslationService.translate('No');
				},
				faicon: ['fal', 'times-circle'],
				value: false
			}
		],
		[SubStep.ExistingOutlets]: [
			{
				get label()
				{
					return TranslationService.translate('T13');
				},
				image: OutletT13Image,
				value: Outlet.T13
			},
			{
				get label()
				{
					return TranslationService.translate('T25');
				},
				image: OutletT25Image,
				value: Outlet.T25
			},
			{
				get label()
				{
					return TranslationService.translate('CEE16 / 230V');
				},
				image: OutletCEE16230Image,
				value: Outlet.CEE16230
			},
			{
				get label()
				{
					return TranslationService.translate('CEE16 / 400V');
				},
				image: OutletCEE16400Image,
				value: Outlet.CEE16400
			},
			{
				get label()
				{
					return TranslationService.translate('CEE32 / 400V');
				},
				image: OutletCEE32400Image,
				value: Outlet.CEE32400
			}
		]
	},
	[Step.Distance]: {},
	[Step.Walls]: {},
	[Step.FusePanel]: {
		[SubStep.FusePanel]: [
			{
				get label()
				{
					return TranslationService.translate('Old distribution box (wooden)');
				},
				image: FusePanelOldImage,
				value: FusePanel.Old
			},
			{
				get label()
				{
					return TranslationService.translate('New distribution box (plastic)');
				},
				image: FusePanelNewImage,
				value: FusePanel.New
			}
		]
	},
	[Step.ExistingCharger]: {},
	[Step.Calculation]: {
		[SubStep.CalculationSummary]: []
	},
	[Step.ContactData]: {},
	[Step.Complete]: []
};

export default stepOptions;

export const getSelectedOption = (step: Step, subStep: string): IInputOption => {
	const options = stepOptions[step];

	if (subStep && options[subStep])
	{
		return options[subStep].find((option) => option.value === store.state.steps[step][subStep]);
	}


	return null;
};

export const getSelectedOptions = (step: Step, subStep: string): IInputOption[] => {
	const options = stepOptions[step];

	if (subStep && options)
	{
		return options[subStep].filter((option) => {
			const data = store.state.steps[step][subStep];

			if (data)
			{
				return data.indexOf(option.value) >= 0;
			}

			return [];
		});
	}
};

	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {Watch, Prop} from 'vue-property-decorator';
	import {createPopper, Instance as PopperInstance} from '@popperjs/core';

	@Component({
		name: 'Popover'
	})
	export default class Popover extends Vue
	{
		@Prop({required: true})
		private trigger: HTMLElement | string;

		@Prop()
		private visibleExternal: boolean;

		private triggerInternal: HTMLElement;
		private visible = false;
		private popper: PopperInstance = null;

		private mounted()
		{
			if (typeof this.trigger === 'string')
			{
				this.triggerInternal = document.querySelector(this.trigger);
			}

			if (this.triggerInternal instanceof HTMLElement)
			{
				this.triggerInternal.addEventListener('mouseenter', this.onMouseEnter);
				this.triggerInternal.addEventListener('mouseleave', this.onMouseLeave);
			}
		}

		//region Watchers
		@Watch('visibleExternal')
		private onVisibleExternalChanged(visible: boolean)
		{
			this.visible = visible;

			if (visible)
			{
				this.createPopper();
			}
			else
			{
				this.destroyPopper();
			}
		}
		//endregion

		private onMouseEnter()
		{
			this.visible = true;
			this.createPopper();
		}

		private onMouseLeave()
		{
			if (this.visibleExternal) return;

			this.visible = false;
			this.destroyPopper();
		}

		private createPopper()
		{
			//eslint-disable-next-line
			this.popper = createPopper(this.triggerInternal, this.$refs.popover as HTMLElement);
		}

		private destroyPopper()
		{
			this.popper.destroy();
		}
	}

import store from '@/store';
import {IApiChargingStationProduct, IApiProduct, IInstallationProduct} from "@/store/interfaces/RootState";
import CalculationProduct from "@/enums/CalculationProduct";
import Step from "@/enums/Step";
import SubStep from "@/enums/SubStep";
import PriceService from "@/services/PriceService";
import SubStepEnum from "@/enums/SubStep";
import {forEach} from "lodash";
import FurtherAction from "@/enums/FurtherAction";
import Parking from "@/enums/Parking";
import Installation from "@/enums/Installation";
import SignalQuality from "@/enums/SignalQuality";
import FusePanel from "@/enums/FusePanel";

class CalculationService
{
    private priceService: PriceService;

    constructor() {
        this.priceService = new PriceService();
    }

    public getHomeCheckCost(): number
    {
        let homeCheckCost = 0;

        if (!this.isHomeCheckIncluded() && store.state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction == FurtherAction.HomeCheckOrdered)
        {
            homeCheckCost += this.priceService.getPriceOfProduct(this.getHomeCheckProduct()).price;
        }

        return homeCheckCost;
    }

    public isHomeCheckIncluded(): boolean
    {
        return store.state.steps[Step.ChargingStation][SubStep.ChargingStation] &&
            store.state.steps[Step.ChargingStation][SubStep.ChargingStation].includesHomeCheck;
    }

    public getHomeCheckProduct(): IApiProduct
    {
        //HC //id 401
        return this.findApiProductByIdentifier(CalculationProduct.HomeCheck);
    }

    public getInstallationCost(): number
    {
        let installationCost = 0;

        forEach(this.getInstallationProducts(), (item: IInstallationProduct) => {

            if(item.product) {

                const price = this.priceService.getPriceOfProduct(item.product);
                if (price != undefined && price.price) {
                    installationCost += item.amount * price.price;
                }
            }
        });

        return installationCost;
    }

    public getInstallationProducts(): IInstallationProduct[]
    {
        const products = [];

        if(!store.state.steps[Step.ChargingStation][SubStepEnum.ChargingStation])
        {
            return products;
        }

        if (
            store.state.steps[Step.ExistingOutlets][SubStepEnum.ExistingOutletsUsable] &&
            store.state.steps[Step.ExistingOutlets][SubStepEnum.ExistingOutletsUseExisting]
        )
        {
            // Demount cee //id 835
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.RemountCEE)
            });
        }
        else
        {
            // installationPermit //id 733
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.InstallationApproval)
            });

            // travelExpense //id 711
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.TravelExpenses)
            });

            if (store.state.steps[Step.Distance][SubStepEnum.Distance] > 0)
            {
                //cable //id 751
                products.push({
                    amount: store.state.steps[Step.Distance][SubStepEnum.Distance],
                    product: this.findApiProductByIdentifier(CalculationProduct.Cable)
                });
            }

            if (
                store.state.steps[Step.Walls][SubStepEnum.WallAmount] > 0 &&
                store.state.steps[Step.Parking][SubStepEnum.Parking] != Parking.Underground
            )
            {
                //WallBreakthrough //id 715
                products.push({
                    amount: store.state.steps[Step.Walls][SubStepEnum.WallAmount],
                    product: this.findApiProductByIdentifier(CalculationProduct.WallBreakthrough)
                });
            } else if(
                store.state.steps[Step.Walls][SubStepEnum.WallAmount] > 0 &&
                store.state.steps[Step.Parking][SubStepEnum.Parking] == Parking.Underground
            ) {
                //WallBreakthroughFireBulkhead //id 716
                products.push({
                    amount: 1,
                    product: this.findApiProductByIdentifier(CalculationProduct.WallBreakthroughFireBulkhead)
                });
                //WallBreakthrough //id 715
                products.push({
                    amount: store.state.steps[Step.Walls][SubStepEnum.WallAmount]-1,
                    product: this.findApiProductByIdentifier(CalculationProduct.WallBreakthrough)
                });
            }

            // proof of safety (sina) //id 730
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.ProofOfSafety)
            });

            // update plans //id 749
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.UpdateLegends)
            });
        }

        // if charger has FI B -> id 735 (CbSwitch),
        // if charger has DC6ms -> id 739 (RcdTypeA),
        // if charger has FI A or nothing -> id 746 (RcdTypeAWithDc),
        // if charger has A & DC -> id 735 (CbSwitch)
        // doesn't make sense... TODO: needs to be clarified with Fabian

        // CbSwitch //id 735
        products.push({
            amount: 1,
            product: this.findApiProductByIdentifier(CalculationProduct.CbSwitch)
        });

        if (store.state.steps[Step.FusePanel][SubStepEnum.FusePanel] == FusePanel.Old)
        {
            // sep kleinverteiler //id 752
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.SmallDistributor)
            });
        }

        // additionally always Sperrschutz //id 817
        products.push({
            amount: 1,
            product: this.findApiProductByIdentifier(CalculationProduct.BlockingContactor)
        });

        if (store.state.steps[Step.Distance][SubStepEnum.Distance] > 0)
        {
            // control cable //id 1005
            products.push({
                amount: store.state.steps[Step.Distance][SubStepEnum.Distance],
                product: this.findApiProductByIdentifier(CalculationProduct.ControlCable)
            });
        }

        if (store.state.steps[Step.ChargingStation][SubStepEnum.ChargingStationSignalQualityWifi] == SignalQuality.Bad)
        {
            // wlan repeater //id 759
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.WlanRepeater)
            });
        }

        if (
            store.state.steps[Step.ChargingStation][SubStepEnum.ChargingStationSignalQualityGprs] == SignalQuality.Bad &&
            store.state.steps[Step.ChargingStation][SubStepEnum.ChargingStationSignalQualityWifi] != SignalQuality.Bad
        ) {
            // gsm repeater //id 834
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.GsmRepeater)
            });
        }

        // power to lan, not used yet
        // installationCosts += 190; // power to lan //id 758
        // products.push(1, this.findApiProductByIdentifier(CalculationProduct.PowerToLan));


        if (store.state.steps[Step.Installation][SubStepEnum.Installation] == Installation.NoMountingOptions)
        {
            // montage pillar //id 748
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.PillarMounting)
            });
        }

        if (store.state.steps[Step.ChargingStation][SubStepEnum.ChargingStationIntelligent])
        {
            // ibs smart //id 815
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.CommissioningSmart)
            });
        } else {
            // ibs default //id 747
            products.push({
                amount: 1,
                product: this.findApiProductByIdentifier(CalculationProduct.CommissioningDefault)
            });
        }


        return products;
    }

    public getChargerCost(chargingStation: IApiProduct): number
    {
        if (store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerAvailable])
        {
            return 0;
        }

        return this.priceService.getPriceOfProduct(chargingStation).price;
    }

    public getPillarCost(pillar: IApiProduct): number
    {
        if (store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerAvailable])
        {
            return 0;
        }

        if (store.state.steps[Step.Installation][SubStepEnum.Installation] != Installation.NoMountingOptions)
        {
            return 0;
        }

        return this.priceService.getPriceOfProduct(pillar).price;
    }

    public getShippingCost(chargingStationProduct: IApiChargingStationProduct): number
    {
        if (store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerAvailable])
        {
            return 0
        }

        let shippingCost = 0;

        shippingCost += chargingStationProduct.chargingStation.shipmentCost;

        if(store.state.steps[Step.Installation][SubStepEnum.Installation] == Installation.NoMountingOptions)
        {
            shippingCost += chargingStationProduct.pillar.shipmentCost;
        }

        if(this.isIncludingVat())
        {
            shippingCost *= 1.077;
        }

        return shippingCost;
    }

    public getHomeCheckCouponCost(): number
    {
        if(!store.state.steps[Step.Calculation][SubStepEnum.CalculationSummary].couponCode ||
            !store.state.steps[Step.Calculation][SubStepEnum.CouponDefinition])
        {
            return 0;
        }

        let couponCost = 0;

        //home check
        if(this.getHomeCheckCost() > 0)
        {
            const price = this.priceService.getPriceOfProduct(this.getHomeCheckProduct());
            if (price != undefined && price.price && price.couponPrice !== null) {
                couponCost += (price.couponPrice - price.price);
            }
        }

        return couponCost;
    }

    public getCouponCost(chargingStation: IApiProduct, pillar: IApiProduct): number
    {
        if(!store.state.steps[Step.Calculation][SubStepEnum.CalculationSummary].couponCode ||
            !store.state.steps[Step.Calculation][SubStepEnum.CouponDefinition])
        {
            return 0;
        }

        let couponCost = 0;

        //installation products
        let couponInstallationCost = 0;
        forEach(this.getInstallationProducts(), (item: IInstallationProduct) => {
            if(item.product) {

                const price = this.priceService.getPriceOfProduct(item.product);
                if (price != undefined && price.price && price.couponPrice !== null) {
                    couponInstallationCost += item.amount * (price.couponPrice - price.price);
                }
            }
        });
        couponCost += couponInstallationCost;

        //charging station
        if(chargingStation && this.getChargerCost(chargingStation) > 0)
        {
            const chargingStationPrice = this.priceService.getPriceOfProduct(chargingStation);
            if(chargingStationPrice != undefined && chargingStationPrice.price && chargingStationPrice.couponPrice !== null)
            {
                couponCost += chargingStationPrice.couponPrice - chargingStationPrice.price;
            }
        }

        // pillar
        if(pillar && this.getPillarCost(pillar) > 0)
        {
            const pillarPrice = this.priceService.getPriceOfProduct(pillar);
            if(pillarPrice != undefined && pillarPrice.price && pillarPrice.couponPrice !== null)
            {
                couponCost += pillarPrice.couponPrice - pillarPrice.price;
            }
        }

        return couponCost;
    }

    public findApiProductByIdentifier(identifier: string): IApiProduct
    {
        return store.state.api.config.calculationProducts.find((apiProduct: IApiProduct) => {
            return apiProduct.calculationProductIdentifier === identifier;
        });
    }

    public isIncludingVat(): boolean
    {
        return !!store.state.api.config.includingVAT;
    }

}

export default CalculationService;
import store from '@/store';
import {debounce} from 'lodash';
import {AppMutation} from '@/store/mutations';

class WindowAnalyzer
{
	private debouncedResize = debounce(this.onResize, 1000);

	constructor()
	{
		window.addEventListener('resize', this.debouncedResize);
	}

	private onResize()
	{
		store.commit(AppMutation.SetWindowWidth, window.innerWidth);
	}
}

export default new WindowAnalyzer();
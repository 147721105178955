
	import Vue from 'vue';
	import Component from 'vue-class-component';

	@Component({
		name: 'WelcomeSubStep'
	})
	export default class WelcomeSubStep extends Vue
	{
		private readonly dhcName = process.env.VUE_APP_DHC_NAME
	}


	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';

	@Component({
		name: 'FusePanelOverview'
	})
	export default class FusePanelOverview extends BaseStepOverview
	{
		private get selectedFusePanel()
		{
			return this.getSelectedOption(this.Step.FusePanel, this.SubStep.FusePanel);
		}
	}

import Vue from 'vue';
import Component from 'vue-class-component';
import {Prop, Watch} from 'vue-property-decorator';

//@ts-ignore
@Component
abstract class BaseFormElement extends Vue
{
	@Prop({
		default: null
	})
	protected id: string;

	@Prop({
		default: 'text'
	})
	protected type: string;

	@Prop({default: false})
	protected multiple: boolean;

	@Prop({
		default: null
	})
	protected icon: string;

	@Prop()
	protected label: string;

	@Prop({default: true})
	protected clearable: boolean;

	@Prop({default: false})
	protected required: boolean;

	@Prop({default: false})
	protected disabled: boolean;

	@Prop({
		default() {
			return (<BaseFormElement> <unknown> this).multiple ? [] : null;
		}
	})
	protected value;

	@Prop({
		default(): [] {
			return [];
		}
	})
	protected suggestions: Array<object>;

	@Prop()
	protected errors: string[];

	@Prop({default: false})
	protected debug: boolean;

	protected hasFocus: boolean;

	protected valid: boolean;

	constructor()
	{
		super();

		this.hasFocus = false;
		this.valid = null;
	}

	protected mounted()
	{
		this.onSetup();
	}

	/**
	 * Override this method in child classes to execute code after mounting
	 */
	protected onSetup()
	{
		//todo: override in child classes
	}

	protected setValue(newValue)
	{
		this.onValueChanged(newValue);

		if (newValue === undefined || newValue === null)
		{
			newValue = null;
		}

		this.$emit('input', newValue);
	}

	protected onValueChanged(newValue)
	{
		if (this.debug)
		{
			//console.debug('Value changed! New value:');
			//console.debug(newValue);
		}
	}

	protected onClearClicked()
	{
		this.$emit('input', this.multiple ? [] : null);
	}

	@Watch('suggestions')
	private onSuggestionsChanged()
	{
		if (this.suggestions.length === 1)
		{
			this.$emit('input', this.suggestions[0]);
		}
	}
}

export default BaseFormElement;


	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {ExistingOutletsMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'ExistingOutletsSubStep',
		components: {SmartCustomSelect}
	})
	export default class ExistingOutletsSubStep extends SubStep
	{
		private onExistingOutletsSelected(existingOutlets: number[])
		{
			this.$store.commit(ExistingOutletsMutation.SetExistingOutlets, existingOutlets);
		}
	}

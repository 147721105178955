
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import FileUpload from '@/components/FileUpload.vue';
	import Notes from '@/components/Notes.vue';

	@Component({
		name: 'Sidebar',
		components: {Notes, FileUpload}
	})
	export default class Sidebar extends Vue
	{

	}

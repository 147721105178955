import Component from 'vue-class-component';
import BaseFormElement from '@/components/Form/BaseFormElement';
import {Prop} from 'vue-property-decorator';
import IInputOption from '@/components/Form/InputOption';

//@ts-ignore
@Component()
abstract class BaseSelect extends BaseFormElement
{
	@Prop()
	protected options: IInputOption[];

	@Prop({default: '--- Please select ---'})
	protected emptyOption: string;

	@Prop({default: true})
	protected searchable: boolean;

	@Prop({default: 300})
	protected maxHeight: number;

	protected mounted()
	{
		if (this.multiple && (this.value === null || this.value === undefined))
		{
			//select the empty option if nothing else is selected
			this.setValue([]);
		}

		/* eslint-disable */
		if (this.debug)
		{
			console.debug('Options:');
			console.debug(this.options);
		}
		/* eslint-enable */
	}

	/**
	 * This method is called if the user selects an option
	 *
	 * @param option The selected option object
	 */
	protected abstract onOptionClicked(option: IInputOption): void;

	/**
	 * This method should return true if the provided option is currently selected
	 *
	 * @param option
	 */
	protected abstract optionSelected(option: IInputOption): boolean;

	/**
	 * This method should return a string representing the current selected value(s)
	 */
	protected abstract get displayValue(): string;

	/**
	 * This method should return whether a valid value is selected or not
	 */
	protected abstract get valueSelected(): boolean;
}

export default BaseSelect;


	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {Prop} from 'vue-property-decorator';

	@Component({
		name: 'NumberInput'
	})
	export default class NumberInput extends Vue
	{
		@Prop()
		private label: string;

		@Prop({required: true})
		private value: number;

		@Prop()
		private min: number;

		@Prop()
		private max: number;

		@Prop({default: 1})
		private step: number;

		@Prop()
		private unit: string;

		private onKeyDown(event: KeyboardEvent)
		{
			const allowedCharacters = '0123456789.-';

			if (!allowedCharacters.includes(event.key) && event.key !== 'Backspace' && event.key !== 'Enter')
			{
				event.preventDefault();
			}
		}

		private onDecreaseClicked()
		{
			this.$emit('input', this.value - this.step);
		}

		private onIncreaseClicked()
		{
			this.$emit('input', this.value + this.step);
		}
	}

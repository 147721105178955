
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {ParkingMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
	import Modal from '@/components/Modal.vue';
  import Parking from "@/enums/Parking";

	@Component({
		name: 'ParkingSubStep',
		components: {Modal, SmartCustomSelect}
	})
	export default class ParkingSubStep extends SubStep
	{
		private onRevertSelectionClicked()
		{
			this.$store.commit(ParkingMutation.SetParking, null);

			this.$modal.hide('public-parking-modal');
		}

		private onParkingSelected(parking)
		{
			this.$store.commit(ParkingMutation.SetParking, parking);

			if (parking)
			{
				//public parking must be handled specifically
				if (parking == Parking.Public)
				{
					this.$modal.show('public-parking-modal');
				}
				else
				{
					this.$emit('next-sub-step');
				}
			}
		}
	}

enum Step
{
	Start = 'step-start',
	Property = 'step-property',
	Parking = 'step-parking',
	Installation = 'step-installation',
	Integration = 'step-integration',
	ChargingStation = 'step-charging-station',
	ExistingOutlets = 'step-existing-outlets',
	Distance = 'step-distance',
	Walls = 'step-walls',
	FusePanel = 'step-fuse-panel',
	ExistingCharger = 'step-existing-charger',
	Calculation = 'step-calculation',
	ContactData = 'step-contact-data',
	Complete = 'step-complete'
}

export default Step;

	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import {mapState} from 'vuex';
	import IRootState from '@/store/interfaces/RootState';
	import Step from '@/enums/Step';
	import SubStep from '@/enums/SubStep';

	@Component({
		name: 'StartOverview',
		computed: {
			...mapState({
				vehicleManufacturer: (state: IRootState) => state.steps[Step.Start][SubStep.VehicleManufacturer],
        vehicle: (state: IRootState) => state.steps[Step.Start][SubStep.Vehicle],
        vehicleUnknown: (state: IRootState) => state.steps[Step.Start][SubStep.VehicleUnknown],
			})
		}
	})
	export default class StartOverview extends BaseStepOverview
	{
		private vehicleManufacturer;
		private vehicle;
    private vehicleUnknown;
	}

enum AppMutation
{
	SetLocale = 'set-locale',
	SetPreSetAssignmentContactId = 'set-pre-set-assignment-contact-id',
	SetTranslationsLoaded = 'set-translations-loaded',
	SetWindowWidth = 'set-window-width',
	SetSubmitted = 'set-submitted',
	ResetStepData = 'reset-step-data'
}

enum VehicleMutation
{
	SetVehicleManufacturer = 'set-vehicle-manufacturer',
	SetVehicle = 'set-vehicle',
	SetVehicleUnknown = 'set-vehicle-unknown',
}

enum PropertyMutation
{
	SetProperty = 'set-property',
	SetPropertyOwnership = 'set-property-ownership'
}

enum ParkingMutation
{
	SetParking = 'set-parking',
	SetParkingOwnership = 'set-parking-ownership'
}

enum InstallationMutation
{
	SetInstallation = 'set-installation'
}

enum IntegrationMutation
{
	SetIntegration = 'set-integration'
}

enum ChargingStationMutation
{
	SetChargingStationIntelligent = 'set-charging-station-intelligent',
	SetChargingStation = 'set-charging-station',
	SetChargingStationConnectivity = 'set-charging-station-connectivity',
	SetChargingStationSignalQualityWifi = 'set-charging-station-signal-quality-wifi',
	SetChargingStationSignalQualityGprs = 'set-charging-station-signal-quality-gprs',
}

enum ExistingOutletsMutation
{
	SetExistingOutletsAvailable = 'set-existing-outlets-available',
	SetExistingOutlets = 'set-existing-outlets',
	SetExistingOutletsUseExisting = 'set-existing-outlets-use-existing'
}

enum DistanceMutation
{
	SetDistance = 'set-distance'
}

enum WallsMutation
{
	SetWallAmount = 'set-wall-amount'
}

enum FusePanelMutation
{
	SetFusePanel = 'set-fuse-panel'
}

enum ExistingChargerMutation
{
	SetExistingChargerAvailable = 'set-existing-charger-available',
	SetExistingChargerPower = 'set-existing-charger-power',
	SetExistingChargerIntelligent = 'set-existing-charger-intelligent'
}

enum CalculationMutation
{
	SetCalculation = 'set-calculation',
	SetCouponCode = 'set-coupon-code',
	SetFurtherAction = 'set-further-action'
}

enum ContactDataMutation
{
	SetResellerContactSelection = 'set-reseller-contact-selection',
	SetInstallerContactSelection = 'set-installer-contact-selection',
	SetRemarks = 'set-remarks',
	SetContactData = 'set-contact-data'
}

export enum HomeCheckAppointmentDateWishesMutation
{
	SetHomeCheckAppointmentDateWishes = 'set-home-check-appointment-date-wishes'
}

enum StepMutation
{
	SetFiles = 'set-files',
	SetNotes = 'set-notes'
}

enum APIMutation
{
	SetAPIConfig = 'set-api-config',
	SetAPIPrices = 'set-api-prices',
	SetAPICouponPrices = 'set-api-coupon-prices',
	SetAPICouponDefinition = 'set-api-coupon-definition',
	SetAPISalutations = 'set-api-salutations',
	SetAPILoading = 'set-api-loading'
}

export {
	AppMutation,
	VehicleMutation,
	PropertyMutation,
	ParkingMutation,
	InstallationMutation,
	ChargingStationMutation,
	IntegrationMutation,
	ExistingOutletsMutation,
	DistanceMutation,
	WallsMutation,
	FusePanelMutation,
	ExistingChargerMutation,
	CalculationMutation,
	ContactDataMutation,
	StepMutation,
	APIMutation
};

	import Component from 'vue-class-component';
	import {mapState} from 'vuex';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import SelectedOptionDisplay from '@/components/SelectedOptionDisplay.vue';
  import IRootState, {IApiChargingStationProduct} from '@/store/interfaces/RootState';
	import SubStep from '@/enums/SubStep';
	import Step from '@/enums/Step';

	@Component({
		name: 'ChargingStationOverview',
		components: {SelectedOptionDisplay},
		computed: {
			...mapState({
				chargingStationProduct: (state: IRootState) => state.steps[Step.ChargingStation][SubStep.ChargingStation]
			})
		}
	})
	export default class ChargingStationOverview extends BaseStepOverview
	{
		private chargingStationProduct: IApiChargingStationProduct;
	}

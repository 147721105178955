
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import CalculationTable from '@/components/Steps/Calculation/CalculationTable.vue';

	@Component({
		name: 'CalculationOverview',
		components: {CalculationTable}
	})
	export default class CalculationOverview extends BaseStepOverview
	{
	}


	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import IInputOption from '@/components/Form/InputOption';

	@Component({
		name: 'InstallationOverview'
	})
	export default class InstallationOverview extends BaseStepOverview
	{
		private get installation(): IInputOption
		{
			return this.getSelectedOption(this.Step.Installation, this.SubStep.Installation);
		}
	}

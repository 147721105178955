import Vue from 'vue';
import {FontAwesomeIcon, FontAwesomeLayers} from '@fortawesome/vue-fontawesome';
import {library} from '@fortawesome/fontawesome-svg-core';
import {far} from '@fortawesome/pro-regular-svg-icons';
//import {fas} from '@fortawesome/pro-solid-svg-icons';
import {fal} from '@fortawesome/pro-light-svg-icons';
import {fad} from '@fortawesome/pro-duotone-svg-icons';
//import {fab} from '@fortawesome/free-brands-svg-icons';

const setupFontAwesome = () => {
	//imports all icons of all styles at once (bundles all icons which is not good)
	//todo: should import only really needed icons
	library.add(fal, far, fad);

	Vue.component('fa-icon', FontAwesomeIcon);
	Vue.component('fa-layers', FontAwesomeLayers);
};

export {
	setupFontAwesome
};
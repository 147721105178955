
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {PropertyMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'PropertySubStep',
		components: {SmartCustomSelect}
	})
	export default class PropertySubStep extends SubStep
	{
		private onPropertySelected(property)
		{
			this.$store.commit(PropertyMutation.SetProperty, property);

			if (property)
			{
				this.$emit('next-sub-step');
			}
		}
	}

import store from '@/store';
import {get} from 'lodash';
import Locale from '@/enums/Locale';
import AjaxRequest from '@/axios/AjaxRequest';
import EventBus from '@/EventBus';
import {GlobalEvent} from '@/events';
import {AppMutation} from '@/store/mutations';

interface ITranslation
{
	key: string;
	value: string;
	location?: string;
}

class TranslationService
{
	private languages: Record<Locale, ITranslation[]> = {
		[Locale.German]: [],
		[Locale.French]: [],
		[Locale.Italian]: [],
		[Locale.English]: []
	};

	constructor()
	{
		EventBus.$on(GlobalEvent.LocaleChanged, this.loadTranslations);

		this.loadTranslations();
	}

	public translate(key: string, location: string = null): string
	{
		const translations = this.getTranslations(store.state.locale);

		const translation = translations.find((_translation) => {
			if (_translation.key === key)
			{
				if (!location)
				{
					return !_translation.location;
				}

				return _translation.location === location;
			}

			return false;
		});

		if (translation && translation.value)
		{
			return translation.value;
		}

		return key;
	}

	public loadTranslations()
	{
		const request = new AjaxRequest({
			url: '/external-translations',
			params: {}
		});

		request.send().then((response) => {
			if (response.wasSuccessful)
			{
				const translations = response.getData();

				for (const translation of translations)
				{
					const languageTranslations = get(this.languages, translation.locale);

					if (languageTranslations)
					{
						languageTranslations.push(translation);
					}
				}

				store.commit(AppMutation.SetTranslationsLoaded, true);
				EventBus.$emit(GlobalEvent.TranslationsLoaded);
			}
		});
	}

	private getTranslations(locale: Locale): ITranslation[]
	{
		return get(this.languages, locale, []);
	}
}

const translationService = new TranslationService();

const callTranslate = (key: string, location?: string) => {
	return translationService.translate.call(translationService, key, location)
};

export default translationService;

export {translationService, callTranslate};
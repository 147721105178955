
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import IInputOption from '@/components/Form/InputOption';
	import {VehicleMutation} from '@/store/mutations';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import SubStep from '@/components/Steps/SubStep';
	import Step from '@/enums/Step';

	@Component({
		name: 'VehicleSubStep',
		components: {SmartCustomSelect}
	})
	export default class VehicleSubStep extends SubStep
	{
		private vehicleOptions: IInputOption[] = [];
		private searchedVehicleOptions: IInputOption[] = [];
		private searchValue: string;

		private mounted()
		{
			this.vehicleOptions = [];
			this.searchValue = '';

			for (const vehicle of this.filteredVehicles)
			{
				this.vehicleOptions.push({
					label: vehicle.name,
					value: vehicle
				});
			}

			this.searchedVehicleOptions = this.vehicleOptions;
		}

		//region Getters
		private get filteredVehicles()
		{
			return this.$store.state.api.config.vehicles.filter((vehicle) =>
			{
				const selectedManufacturer = this.$store.state.steps[Step.Start][this.SubStep.VehicleManufacturer];

				if (selectedManufacturer)
				{
					return vehicle.manufacturer === selectedManufacturer.id;
				}

				return true;
			});
		}

		//endregion

		private onSearch()
		{
			this.searchedVehicleOptions = this.vehicleOptions.filter((vehicleOption) =>
			{
				return vehicleOption.label.toLowerCase().includes(this.searchValue.toLowerCase().trim());
			});
		}

		private onVehicleSelected(vehicle)
		{
			this.$store.commit(VehicleMutation.SetVehicle, vehicle);

			EventBus.$emit(GlobalEvent.NextStep);
		}

		private get showVehicleUnknownButton(): boolean
		{
			return this.$store.state.api.config.showOptionUnknownVehicle;
		}

		private onVehicleUnknownClicked()
		{
			this.$store.commit(VehicleMutation.SetVehicleUnknown, true);

			EventBus.$emit(GlobalEvent.NextStep);
		}
	}

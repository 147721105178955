
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import IInputOption from '@/components/Form/InputOption';
	import {VehicleMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';

	@Component({
		name: 'ManufacturerSubStep',
		components: {SmartCustomSelect}
	})
	export default class ManufacturerSubStep extends SubStep
	{
		private manufacturerOptions: IInputOption[] = [];
		private searchedManufacturerOptions: IInputOption[] = [];
		private searchValue: string;

		private mounted()
		{
			this.manufacturerOptions = [];
			this.searchedManufacturerOptions = [];
			this.searchValue = '';

			for (const manufacturer of this.$store.state.api.config.vehicleManufacturers)
			{
				this.manufacturerOptions.push({
					label: manufacturer.shortName,
					value: manufacturer
				});
			}

			this.searchedManufacturerOptions = this.manufacturerOptions;
		}

		private onSearch()
		{
			this.searchedManufacturerOptions = this.manufacturerOptions.filter((manufacturerOption) =>
			{
				return manufacturerOption.label.toLowerCase().includes(this.searchValue.toLowerCase().trim());
			});
		}

		private onManufacturerSelected(manufacturer)
		{
			this.$store.commit(VehicleMutation.SetVehicleManufacturer, manufacturer);

			if (manufacturer)
			{
				this.$emit('next-sub-step');
			}
		}

		private get showVehicleUnknownButton(): boolean
		{
			return this.$store.state.api.config.showOptionUnknownVehicle;
		}

		private onVehicleUnknownClicked()
		{
			this.$store.commit(VehicleMutation.SetVehicleUnknown, true);

			EventBus.$emit(GlobalEvent.NextStep);
		}
	}

import {Route} from 'vue-router';
import {forEach} from 'lodash';
import router, {routes} from '@/router';
import {forEachRight} from 'lodash';
import ICustomRouteConfig from '@/router/interfaces/CustomRouteConfig';
import ISubStepDefinition from '@/interfaces/SubStepDefinition';
import Step from '@/enums/Step';
import ChargingStationService from "@/services/ChargingStationService";
import EventBus from "@/EventBus";
import {GlobalEvent} from "@/events";

class StepService
{
	public slideTransition = 'slide-left';

	public get isFirst(): boolean
	{
		return this.getCurrentStepIndex() <= 0;
	}

	public get isLast(): boolean
	{
		return this.getCurrentStepIndex() >= routes.length - 1;
	}

	/**
	 * Try to navigate to the previous step in the configuration if one exists and it is actually accessible
	 */
	public previous()
	{
		if (!this.isFirst)
		{
			const previousStep = routes[this.getCurrentStepIndex() - 1];

			//check if previous step is accessible
			if (this.getIsStepAccessible(previousStep))
			{
				//slide direciton will be set in route guard!
				router.push(previousStep);
			}
		}
	}

	/**
	 * Try to navigate to the next step in the configuration if one exists and it is actually accessible
	 */
	public next()
	{
		if(!ChargingStationService.productsAvailable())
		{
			EventBus.$emit(GlobalEvent.ShowChargingStationError);
			return;
		}

		if (!this.isLast)
		{
			const nextStep = routes[this.getCurrentStepIndex() + 1];

			//check if next step is accessible
			if (this.getIsStepAccessible(nextStep))
			{
				//slide direciton will be set in route guard!
				router.push(nextStep);
			}
		}
	}

	public getCurrentStepIndex(): number
	{
		return routes.findIndex((route) => route.meta.stepId === router.currentRoute.meta.stepId);
	}

	public getStepIndex(step: Route | ICustomRouteConfig)
	{
		return routes.findIndex((route) => step.meta.stepId === route.meta.stepId);
	}

	/**
	 * Retrieve step by given id
	 *
	 * @param id
	 */
	public getStepById(id: Step)
	{
		for (const route of routes)
		{
			if (route.meta.stepId === id)
			{
				return route;
			}
		}
	}

	/**
	 * Check if all substeps of provided step are finished, which results in a finished step
	 *
	 * @param route
	 */
	public getIsStepFinished(route: Route | ICustomRouteConfig): boolean
	{
		for (const subStep of route.meta.subSteps)
		{
			if (!subStep.isSubStepFinished())
			{
				return false;
			}
		}

		return true;
	}

	/**
	 * Checks route (step) configuration to find out whether the provided step (route) is accessible
	 *
	 * @param route
	 */
	public getIsStepAccessible(route: Route | ICustomRouteConfig): boolean
	{
		const requiredStepIds = route.meta.requiredSteps;

		for (const requiredStepId of requiredStepIds)
		{
			if (!this.getIsStepFinished(this.getStepById(requiredStepId)))
			{
				return false;
			}
		}

		return true;
	}

	/**
	 * Checks if sub step is accessible
	 *
	 * @param subStep
	 *
	 * @return boolean
	 */
	public getIsSubStepAccessible(subStep: ISubStepDefinition)
	{
		let accessible = true;

		forEach(subStep.requirements, (requirement) => {
			if (!requirement())
			{
				accessible = false;
			}
		});

		if (typeof subStep.disabled === 'function')
		{
			if(subStep.disabled())
			{
				accessible = false;
			}
		}
		else
		{
			if(subStep.disabled)
			{
				accessible = false;
			}
		}

		return accessible;
	}

	/**
	 * Checks if required property is a function returning boolean or a simple boolean
	 * If it's a function it calls the function and returns the result
	 * Otherwise it simply returns the boolean value
	 *
	 * @param subStep
	 */
	public getIsSubStepRequired(subStep: ISubStepDefinition): boolean
	{
		if (typeof subStep.required === 'function')
		{
			return subStep.required();
		}

		return subStep.required;
	}

	/**
	 * Retrieve all sub-steps of step which are currently accessible
	 *
	 * @param step
	 */
	public getAccessibleSubSteps(step: ICustomRouteConfig): ISubStepDefinition[]
	{
		return step.meta.subSteps.filter((subStep) => this.getIsSubStepAccessible(subStep));
	}

	/**
	 * Check all steps whether they are accessible or not and return the last result
	 * Starts at index 0 and iterates all routes (steps)
	 */
	public findHighestAvailableStep(): ICustomRouteConfig
	{
		let availableStep = null;

		forEachRight(routes, (route) => {
			if (this.getIsStepAccessible(route))
			{
				return availableStep = route;
			}
		});

		return availableStep;
	}
}

export default new StepService();
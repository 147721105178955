
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';

	@Component({
		name: 'ExistingOutletsOverview'
	})
	export default class ExistingOutletsOverview extends BaseStepOverview
	{
		private get selectedOutlets()
		{
			return this.getSelectedOptions(this.Step.ExistingOutlets, this.SubStep.ExistingOutlets);
		}
	}

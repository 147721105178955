import {DirectiveBinding} from 'vue/types/options';
import {VNode} from 'vue';

export default {
    inserted: (element: HTMLElement, binding: DirectiveBinding, vnode: VNode) => {
        const image = element as HTMLImageElement;

        if (image)
        {
            const $loader = document.createElement('div');
            $loader.classList.add('loader');

            image.parentNode.replaceChild($loader, image);

            image.onload = () => {
                if ($loader.parentNode)
                {
                    $loader.parentNode.replaceChild(image, $loader);
                }
            };
        }
    }
};

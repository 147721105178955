
	import Component from 'vue-class-component';
	import NumberInput from '@/components/Form/NumberInput.vue';
	import {WallsMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
	import WallPenetrationImage from '@/assets/img/wall-penetration.png';

	@Component({
		name: 'WallAmountSubStep',
		components: {NumberInput}
	})
	export default class WallAmountSubStep extends SubStep
	{
		private WallPenetrationImage = WallPenetrationImage;

		private mounted()
		{
			if (this.$store.state.steps[this.Step.Walls][this.SubStep.WallAmount] === null)
			{
				this.$store.commit(WallsMutation.SetWallAmount, 0);
			}
		}

		private onWallAmountInput(wallAmount: number)
		{
			this.$store.commit(WallsMutation.SetWallAmount, wallAmount);
		}
	}

import Vue from 'vue';
import App from './App.vue';
//import './registerServiceWorker';
import router from './router';
import store from './store';
import VueAwesome from 'vue-awesome';
import TooltipDirective from '@/directives/Tooltip';
import ImageLoaderDirective from '@/directives/ImageLoader';
import Modal from '@/lib/Modal';
import MoneyFormatter from '@/services/MoneyFormatter';
import SmoothReflow from 'vue-smooth-reflow';
import Select2 from 'v-select2-component';

import './validation';

//icons custom
import '@/icons-custom';

//icons font awesome
import {setupFontAwesome} from '@/icons-fa';
setupFontAwesome();

//region Styles
//import normalize.css
import 'normalize.css';

//import bootstrap
import 'bootstrap/dist/css/bootstrap-grid.min.css';

import './assets/css/transitions.scss';
import './assets/css/global.scss';
import './assets/css/colors.scss';
import './assets/css/tooltip.scss';
import './assets/css/steps.scss';
import './assets/css/table.scss';
import TranslationService, {callTranslate} from '@/services/TranslationService';
//endregion

Vue.config.productionTip = false;

Vue.directive('tooltip', TooltipDirective);
Vue.directive('image-loader', ImageLoaderDirective);

Vue.mixin(SmoothReflow);

Vue.component('v-icon', VueAwesome);
//eslint-disable-next-line
Vue.component('Select2', Select2);

Vue.prototype.$modal = Modal;
Vue.prototype.$moneyFormatter = MoneyFormatter;
Vue.prototype.$translator = TranslationService;
Vue.prototype.$t = callTranslate;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
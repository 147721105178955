
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {CalculationMutation} from '@/store/mutations';
	import SubStepComponent from '@/components/Steps/SubStep';
	import StartOverview from '@/components/StepOverview/StartOverview.vue';
	import {mapState} from 'vuex';
	import IRootState from '@/store/interfaces/RootState';
	import SubStep from '@/enums/SubStep';
	import Step from '@/enums/Step';
	import PropertyOverview from '@/components/StepOverview/PropertyOverview.vue';
	import ParkingOverview from '@/components/StepOverview/ParkingOverview.vue';
	import InstallationOverview from '@/components/StepOverview/InstallationOverview.vue';
	import IntegrationOverview from '@/components/StepOverview/IntegrationOverview.vue';
	import ChargingStationOverview from '@/components/StepOverview/ChargingStationOverview.vue';
	import ExistingOutletsOverview from '@/components/StepOverview/ExistingOutletsOverview.vue';
	import DistanceOverview from '@/components/StepOverview/DistanceOverview.vue';
	import WallsOverview from '@/components/StepOverview/WallsOverview.vue';
	import FusePanelOverview from '@/components/StepOverview/FusePanelOverview.vue';
  import FurtherAction from '@/enums/FurtherAction';

  @Component({
		name: 'CalculationSummarySubStep',
		components: {
			FusePanelOverview,
			WallsOverview,
			DistanceOverview,
      ExistingOutletsOverview,
      ChargingStationOverview,
      IntegrationOverview,
      InstallationOverview,
      ParkingOverview,
      PropertyOverview,
      StartOverview,
      SmartCustomSelect
    },
		computed: {
			...mapState({
				vehicle: (state: IRootState) => state.steps[Step.Start][SubStep.Vehicle],
        showOptionHomeCheckOrder: (state: IRootState) => state.api.config.showOptionHomeCheckOrder
			})
		}
	})
	export default class CalculationSummarySubStep extends SubStepComponent
	{
    private readonly FurtherAction = FurtherAction;

    private onFurtherActionSelected(value: number)
		{
			this.$store.commit(CalculationMutation.SetFurtherAction, value);

			if (value)
			{
				this.$emit('next-step');
			}
		}
	}

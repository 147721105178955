
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {Prop} from 'vue-property-decorator';

	@Component({
		name: 'SwitchInput'
	})
	export default class SwitchInput extends Vue
	{
		@Prop()
		private label: string;

		@Prop({required: true})
		private value: boolean;
	}

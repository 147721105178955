
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import IInputOption from '@/components/Form/InputOption';
	import Step from '@/enums/Step';

	@Component({
		name: 'ParkingOverview'
	})
	export default class ParkingOverview extends BaseStepOverview
	{
		private get parking(): IInputOption
		{
			return this.getSelectedOption(Step.Parking, this.SubStep.Parking);
		}

		private get ownership(): IInputOption
		{
			return this.getSelectedOption(Step.Parking, this.SubStep.ParkingOwnership);
		}
	}

import store from '@/store';

class MoneyFormatter
{
	public static format(amount: number): string
	{
		let currencyCode = 'CHF';

		if(store.state.api.config.currencyCode)
		{
			currencyCode = store.state.api.config.currencyCode
		}

		amount /= 100;

		return amount.toLocaleString('de-CH', {style: 'currency', currency: currencyCode});
	}
}

export default MoneyFormatter;
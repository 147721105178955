
	import Component from 'vue-class-component';
	import NumberInput from '@/components/Form/NumberInput.vue';
	import {DistanceMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
	import WindowAnalyzer from '@/services/WindowAnalyzer';

	@Component({
		name: 'DistanceSubStep',
		components: {NumberInput}
	})
	export default class DistanceSubStep extends SubStep
	{
		private readonly WindowAnalyzer = WindowAnalyzer;

		private mounted()
		{
			if (this.$store.state.steps[this.Step.Distance][this.SubStep.Distance] === null)
			{
				this.$store.commit(DistanceMutation.SetDistance, 0);
			}
		}

		private onDistanceInput(distance: number)
		{
			this.$store.commit(DistanceMutation.SetDistance, distance);
		}
	}

import Vue from 'vue';
import Component from 'vue-class-component';
import Step from '@/enums/Step';
import StepOptions from '@/step-options';
import SubStepEnum from '@/enums/SubStep';

//@ts-ignore
@Component
abstract class SubStep extends Vue
{
	protected readonly StepOptions = StepOptions;
	protected readonly Step = Step;
	protected readonly SubStep = SubStepEnum;
}

export default SubStep;
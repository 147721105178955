enum AppAction
{
	SwitchLocale = 'switch-locale'
}

enum APIAction
{
	LoadConfig = 'load-config',
	LoadPrices = 'load-prices',
	LoadSalutations = 'load-salutations',
	LoadAll = 'load-all',
	ValidateCoupon = 'validate-coupon',
	LoadCouponPrices = 'load-coupon-prices',
	SubmitData = 'submit-data'
}

export {
	AppAction,
	APIAction
};
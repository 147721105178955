enum SubStep
{
	VehicleManufacturer = 'sub-step-vehicle-manufacturer',
	Vehicle = 'sub-step-vehicle',
	VehicleUnknown = 'sub-step-vehicle-unknown',
	Property = 'sub-step-property',
	PropertyOwnership = 'sub-step-property-ownership',
	Parking = 'sub-step-parking',
	ParkingOwnership = 'sub-step-parking-ownership',
	Installation = 'sub-step-installation',
	Integration = 'sub-step-integration',
	ChargingStationIntelligent = 'sub-step-charging-station-intelligent',
	ChargingStation = 'sub-step-charging-station',
	ChargingStationConnectivity = 'sub-step-charging-station-connectivity',
	ChargingStationSignalQualityWifi = 'sub-step-charging-station-signal-quality-wifi',
	ChargingStationSignalQualityGprs = 'sub-step-charging-station-signal-quality-gprs',
	ExistingOutletsAvailable = 'sub-step-existing-outlets-available',
	ExistingOutlets = 'sub-step-existing-outlets',
	ExistingOutletsUsable = 'sub-step-existing-outlets-usable',
	ExistingOutletsUseExisting = 'sub-step-existing-outlets-use-existing',
	Distance = 'sub-step-distance',
	WallAmount = 'sub-step-wall-amount',
	FusePanel = 'sub-step-fuse-panel',
	ExistingChargerAvailable = 'sub-step-existing-charger-available',
	ExistingChargerPower = 'sub-step-existing-charger-power',
	ExistingChargerIntelligent = 'sub-step-existing-charger-intelligent',
	Calculation = 'sub-step-calculation',
	CalculationSummary = 'sub-step-calculation-summary',
	CouponDefinition = 'sub-step-coupon-definition',
	ResellerContactSelection = 'sub-step-reseller-contact-selection',
	InstallerContactSelection = 'sub-step-installer-contact-selection',
	Remarks = 'sub-step-remarks',
	ContactData = 'sub-step-contact-data',
	HomeCheckAppointmentDateWishes = 'sub-step-home-check-appointment-date-wishes',
	Complete = 'sub-step-complete'
}

export default SubStep;
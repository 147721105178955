
	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {Prop} from 'vue-property-decorator';
	import IInputOption from '@/components/Form/InputOption';

	@Component({
		name: 'SelectedOptionDisplay'
	})
	export default class SelectedOptionDisplay extends Vue
	{
		@Prop()
		private option: IInputOption;

		@Prop()
		private options: IInputOption[];
	}

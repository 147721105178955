
	import Component from 'vue-class-component';
	import SwitchInput from '@/components/Form/SwitchInput.vue';
	import {ChargingStationMutation, IntegrationMutation} from '@/store/mutations';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SubStep from '@/components/Steps/SubStep';
  import Integration from "@/enums/Integration";

	@Component({
		name: 'IntegrationSubStep',
		components: {SmartCustomSelect, SwitchInput}
	})
	export default class IntegrationSubStep extends SubStep
	{
		private mounted()
		{
			if (!this.$store.state.steps[this.Step.Integration][this.SubStep.Integration])
			{
				this.$store.commit(IntegrationMutation.SetIntegration, []);
			}
		}

		private onIntegrationSelected(value: number[])
		{
			const oldValue = this.$store.state.steps[this.Step.Integration][this.SubStep.Integration];

			if (value.includes(Integration.NoIntegration) && !oldValue.includes(Integration.NoIntegration))
			{
				this.$store.commit(IntegrationMutation.SetIntegration, [Integration.NoIntegration]);
			}
			else
			{
				if (value.indexOf(Integration.NoIntegration) >= 0)
				{
					value.splice(value.indexOf(Integration.NoIntegration), 1);
				}

				this.$store.commit(IntegrationMutation.SetIntegration, value);

				//if anything is selected, make sure a possibly existing selection of charging station will be reset
				if (value && value.length)
				{
					this.$store.commit(ChargingStationMutation.SetChargingStationIntelligent, null);
				}
			}
		}
	}

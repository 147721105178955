
	import Component from 'vue-class-component';
	import BaseSelect from '@/components/Form/BaseSelect';
	import {Prop} from 'vue-property-decorator';
	import IInputOption from '@/components/Form/InputOption';
	import {isEqual, clone} from 'lodash';

	/**
	 * Important: You *always* need to define "slot-scope" attribute in the parent component, otherwise nothing will be rendered!
	 */
	@Component({
		name: 'SmartCustomSelect'
	})
	export default class SmartCustomSelect extends BaseSelect
	{
		@Prop({default: false})
		private hideOthersIfSelected: boolean;

		@Prop({default: 'center'})
		private align: string;

		protected get displayValue(): string
		{
			return '';
		}

		private get filteredOptions(): IInputOption[]
		{
			return this.options.filter((option) => {
				return !this.hideOthersIfSelected || (this.hideOthersIfSelected && this.optionSelected(option));
			});
		}

		protected onOptionClicked(option: IInputOption): void
		{
			if (option.disabled && option.disabled()) return;

			if (this.multiple)
			{
				const value = clone(this.value);

				if (this.optionSelected(option))
				{
					this.setValue(value.filter((value: any) => !isEqual(value, option.value)));
				}
				else
				{
					value.push(option.value);

					this.setValue(value);
				}
			}
			else
			{
				this.setValue(option.value);
			}
		}

		protected optionSelected(option: IInputOption): boolean
		{
			//allow falsy values if not null/undefined
			if (this.value !== null && this.value !== undefined)
			{
				if (this.multiple && Array.isArray(this.value))
				{
					return !!this.value.find((value: any) => isEqual(value, option.value));
				}
				else
				{
					return isEqual(this.value, option.value);
				}
			}

			return false;
		}

		protected get valueSelected(): boolean
		{
			if (this.multiple && Array.isArray(this.value))
			{
				return !!this.value.length;
			}
			else
			{
				return this.value !== null && this.value !== undefined;
			}
		}
	}


	import Component from 'vue-class-component';
	import {ChargingStationMutation} from '@/store/mutations';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'ChargingStationSignalQualityGprsSubStep',
		components: {SmartCustomSelect}
	})
	export default class ChargingStationSignalQualityGprsSubStep extends SubStep
	{
		private onSignalQualitySelected(signalQuality: number)
		{
			this.$store.commit(ChargingStationMutation.SetChargingStationSignalQualityGprs, signalQuality);

			if (signalQuality)
			{
				this.$emit('next-sub-step');
			}
		}
	}

import store from '@/store';
import Step from "@/enums/Step";
import SubStep from "@/enums/SubStep";
import {IApiChargingStationProduct} from "@/store/interfaces/RootState";
import Integration from "@/enums/Integration";

class ChargingStationService
{
	public productsAvailable(): boolean
	{
		return this.getFilteredChargingStationProducts().length > 0;
	}

	public getFilteredChargingStationProducts(): IApiChargingStationProduct[]
	{
		let products = store.state.api.config.chargingStationProducts;

		// filter regarding vehicle plug
		if(store.state.steps[Step.Start][SubStep.Vehicle])
		{
			products = products.filter((product: IApiChargingStationProduct) => {
				const vehicle = store.state.steps[Step.Start][SubStep.Vehicle];

				for (const connectorAC of vehicle.connectorsAC)
				{
					if(connectorAC.type === 'type-1' && product.hasType1Connectivity)
					{
						return true;
					}
					if(connectorAC.type === 'type-2' && product.hasType2Connectivity)
					{
						return true;
					}
				}

				for (const connectorDC of vehicle.connectorsDC)
				{
					if(connectorDC.type === 'chademo' && product.hasChademoConnectivity)
					{
						return true;
					}
					if(connectorDC.type === 'ccs' && product.hasCcsConnectivity)
					{
						return true;
					}
				}

				return false;
			});
		}

		//filter regarding integration
		const integration = store.state.steps[Step.Integration][SubStep.Integration];
		if(integration && integration.length > 0 && !integration.includes(Integration.NoIntegration))
		{
			// #1 "Gebäudesteuerung/Automatisierung" needs "IoT / BUS"
			if(integration.includes(Integration.HomeAutomation))
			{
				products = products.filter((product: IApiChargingStationProduct) => {
					return product.simpleFeatures.includes('iot');
				});
			}

			// #2 "Photovoltaik" needs "PV"
			if(integration.includes(Integration.Photovoltaics))
			{
				products = products.filter((product: IApiChargingStationProduct) => {
					return product.simpleFeatures.includes('pv');
				});
			}

			// #3 "Batteriespeicher" needs "IoT / BUS"
			if(integration.includes(Integration.BatteryStorage))
			{
				products = products.filter((product: IApiChargingStationProduct) => {
					return product.simpleFeatures.includes('iot');
				});
			}

			// #4 "Batteriespeicher" needs "OCPP / Billing" and "Charge Management"
			if(integration.includes(Integration.ChargingProcessAccountance))
			{
				products = products.filter((product: IApiChargingStationProduct) => {
					return product.simpleFeatures.includes('billing') && product.simpleFeatures.includes('charge-management');
				});
			}
		}

		//filter regarding intelligent charging station
		const intelligent = store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent];
		if(typeof intelligent === 'boolean')
		{
			products = products.filter((product: IApiChargingStationProduct) => {
				return product.isIntelligent === intelligent;
			});
		}

		return products;
	}
}

export default new ChargingStationService();
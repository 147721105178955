
import Component from 'vue-class-component';
import SubStepComponent from '@/components/Steps/SubStep';
import CalculationTable from '@/components/Steps/Calculation/CalculationTable.vue';
import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
import {mapState} from 'vuex';
import IRootState from '@/store/interfaces/RootState';
import SubStep from '@/enums/SubStep';
import Step from '@/enums/Step';
import SubStepEnum from '@/enums/SubStep';
import {ExistingOutletsMutation, ExistingChargerMutation} from '@/store/mutations';

@Component({
	name: 'CalculationSubStep',
	components: {SmartCustomSelect, CalculationTable},
	computed: {
		...mapState({
			vehicle: (state: IRootState) => state.steps[Step.Start][SubStep.Vehicle],
			chargingStationProduct: (state: IRootState) => state.steps[Step.ChargingStation][SubStep.ChargingStation]
		})
	}
})
export default class CalculationSubStep extends SubStepComponent
{
	private get useExistingOutlet(): boolean
	{
		return this.$store.state.steps[Step.ExistingOutlets][SubStepEnum.ExistingOutletsUseExisting];
	}

	private set useExistingOutlet(value: boolean)
	{
		this.$store.commit(ExistingOutletsMutation.SetExistingOutletsUseExisting, value);
	}

	private get showExistingChargerQuestion(): boolean
	{
		return this.$store.state.api.config.showOptionExistingCharger;
	}

	private get existingChargerAvailable(): boolean
	{
		return this.$store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerAvailable];
	}

	private set existingChargerAvailable(value: boolean)
	{
		this.$store.commit(ExistingChargerMutation.SetExistingChargerAvailable, value);
	}

	private get existingChargerPower(): number
	{
		return this.$store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerPower];
	}

	private set existingChargerPower(value: number)
	{
		this.$store.commit(ExistingChargerMutation.SetExistingChargerPower, value);
	}

	private get existingChargerIntelligent(): boolean
	{
		return this.$store.state.steps[Step.ExistingCharger][SubStepEnum.ExistingChargerIntelligent];
	}

	private set existingChargerIntelligent(value: boolean)
	{
		this.$store.commit(ExistingChargerMutation.SetExistingChargerIntelligent, value);
	}
}

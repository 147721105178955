import AjaxRequest from '@/axios/AjaxRequest';
import {ICountry} from '@/interfaces/country.interface';
import {ICompany} from '@/interfaces/company.interface';
import {IBusinessPartner} from '@/interfaces/business-partner.interface';

export class CompanyService
{
	public static async getCompanies(name: string, country: ICountry): Promise<ICompany[]>
	{
		const request = new AjaxRequest({
			url: `/contacts/company/${name}/${country.code}`
		});

		const response = await request.send();

		if (response.wasSuccessful)
		{
			return response.getData();
		}

		return [];
	}

	public static async getSapBusinessPartnerByCompanyUid(uid: string): Promise<IBusinessPartner[]>
	{
		const request = new AjaxRequest({
			url: `/business-partners/${uid}`
		});

		const response = await request.send();

		if (response.wasSuccessful)
		{
			return response.getData();
		}

		return [];
	}
}
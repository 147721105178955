
	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SwitchInput from '@/components/Form/SwitchInput.vue';
	import {ExistingOutletsMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';

	@Component({
		name: 'ExistingOutletsAvailableSubStep',
		components: {SwitchInput, SmartCustomSelect}
	})
	export default class ExistingOutletsAvailableSubStep extends SubStep
	{
		private onExistingOutletsAvailableSwitched(value: boolean)
		{
			this.$store.commit(ExistingOutletsMutation.SetExistingOutletsAvailable, value);

			if (value === false)
			{
				this.$emit('next-step');
			}
			else
			{
				this.$emit('next-sub-step');
			}
		}
	}

import Vue from 'vue';
import Vuex from 'vuex';
import {Route} from 'vue-router';
import {
	APIMutation,
	AppMutation,
	CalculationMutation,
	ChargingStationMutation,
	ContactDataMutation,
	DistanceMutation,
	ExistingChargerMutation,
	ExistingOutletsMutation,
	FusePanelMutation, HomeCheckAppointmentDateWishesMutation,
	InstallationMutation,
	IntegrationMutation,
	ParkingMutation,
	PropertyMutation,
	StepMutation,
	VehicleMutation,
	WallsMutation
} from '@/store/mutations';
import Step from '@/enums/Step';
import IRootState, {
	IApiChargingStationProduct, IApiContact,
	IApiPrice,
	IApiProduct,
	IApiVehicle,
	IApiVehicleManufacturer
} from '@/store/interfaces/RootState';
import {APIAction, AppAction} from '@/store/actions';
import AjaxRequest from '@/axios/AjaxRequest';
import {cloneDeep} from 'lodash';
import Locale from '@/enums/Locale';
import SubStep from '@/enums/SubStep';
import EventBus from '@/EventBus';
import {GlobalEvent} from '@/events';
import Outlet from "@/enums/Outlet";
import {IApiAppointmentDateWish} from '@/interfaces/ApiAppointmentDateWish';

Vue.use(Vuex);

const getDefaultState = (): IRootState => {
	return {
		//whether debug mode is on or not
		debug: process.env.VUE_APP_DEBUG === 'true',
		locale: Locale.German,
		translationsLoaded: false,
		preSetAssignmentContactId: null,
		windowWidth: window.innerWidth,
		submitted: false,
		steps: {
			[Step.Start]: {
				[SubStep.VehicleManufacturer]: null,
				[SubStep.Vehicle]: null,
				[SubStep.VehicleUnknown]: null,
				notes: null
			},
			[Step.Property]: {
				[SubStep.Property]: null,
				[SubStep.PropertyOwnership]: null,
				files: [],
				notes: null
			},
			[Step.Parking]: {
				[SubStep.Parking]: null,
				[SubStep.ParkingOwnership]: null,
				files: [],
				notes: null
			},
			[Step.Installation]: {
				[SubStep.Installation]: null,
				files: [],
				notes: null
			},
			[Step.Integration]: {
				[SubStep.Integration]: [],
				files: [],
				notes: null
			},
			[Step.ChargingStation]: {
				[SubStep.ChargingStationIntelligent]: null,
				[SubStep.ChargingStation]: null,
				[SubStep.ChargingStationConnectivity]: [],
				[SubStep.ChargingStationSignalQualityWifi]: null,
				[SubStep.ChargingStationSignalQualityGprs]: null,
				notes: null
			},
			[Step.ExistingOutlets]: {
				[SubStep.ExistingOutletsAvailable]: null,
				[SubStep.ExistingOutlets]: [],
				[SubStep.ExistingOutletsUsable]: false,
				[SubStep.ExistingOutletsUseExisting]: false,
				files: [],
				notes: null
			},
			[Step.Distance]: {
				[SubStep.Distance]: null,
				files: [],
				notes: null
			},
			[Step.Walls]: {
				[SubStep.WallAmount]: null,
				files: [],
				notes: null
			},
			[Step.FusePanel]: {
				[SubStep.FusePanel]: null,
				files: [],
				notes: null
			},
			[Step.ExistingCharger]: {
				[SubStep.ExistingChargerAvailable]: false,
				[SubStep.ExistingChargerPower]: null,
				[SubStep.ExistingChargerIntelligent]: null
			},
			[Step.Calculation]: {
				[SubStep.Calculation]: {
					homeCheck: null,
					installation: null,
					chargingStation: null,
					pillar: null,
					shipping: null,
					discount: null
				},
				[SubStep.CalculationSummary]: {
					couponCode: null,
					furtherAction: null
				},
				[SubStep.CouponDefinition]: null
			},
			[Step.ContactData]: {
				[SubStep.ResellerContactSelection]: null,
				[SubStep.InstallerContactSelection]: null,
				[SubStep.Remarks]: null,
				[SubStep.ContactData]: {
					id: null,
					isCompanyOnlyContact: false,
					firstName: null,
					lastName: null,
					phone: null,
					mobile: null,
					email: null,
					company: null,
					companyNotRegistered: false,
					uid: null,
					sapBusinessPartnerId: null,
					segment: null,
					address1: null,
					postCode: null,
					city: null,
					salutation: null,
					language: null,
					country: null
				},
				[SubStep.HomeCheckAppointmentDateWishes]: []
			}
		},
		api: {
			config: {
				priceGroupId: null,
				includingVAT: false,
				currencyCode: null,
				taxRate: null,
				countryId: null,
				countryCode: '',
				showCouponCode: false,
				showOptionUnknownVehicle: false,
				showOptionExistingCharger: false,
				showOptionHomeCheckOrder: false,
				showOptionResellerAutomaticSelection: false,
				showOptionContactApproachType: false,
				vehicleManufacturers: [],
				vehicles: [],
				chargingStationProducts: [],
				calculationProducts: [],
				supportContact: null,
				resellerContacts: [],
				installerContacts: [],
				homeCheckOrderInfoText: null,
			},
			prices: {
				rrp: [],
				relative: [],
				discount: [],
				voucher: []
			},
			couponPrices: {
				rrp: [],
				relative: [],
				discount: [],
				voucher: []
			},
			salutations: [],
			loading: false
		}
	};
};

export default new Vuex.Store({
	strict: process.env.NODE_ENV !== 'production',
	state: getDefaultState(),
	mutations: {
		[AppMutation.SetLocale]: (state: IRootState, locale) => {
			state.locale = locale;

			EventBus.$emit(GlobalEvent.LocaleChanged);
		},
		[AppMutation.SetPreSetAssignmentContactId]: (state: IRootState, contactId: number) => {
			state.preSetAssignmentContactId = contactId;
			state.steps[Step.ContactData][SubStep.ResellerContactSelection] = contactId;
		},
		[AppMutation.SetTranslationsLoaded]: (state: IRootState, translationsLoaded: boolean) => {
			state.translationsLoaded = translationsLoaded;
		},
		[AppMutation.SetWindowWidth]: (state: IRootState, windowWidth: number) => {
			state.windowWidth = windowWidth;
		},
		[AppMutation.SetSubmitted]: (state: IRootState, submitted: boolean) => {
			state.submitted = submitted;
		},
		[AppMutation.ResetStepData]: (state: IRootState) => {
			state.steps = getDefaultState().steps;
		},

		[VehicleMutation.SetVehicleManufacturer]: (state: IRootState, manufacturer) => {
			state.steps[Step.Start][SubStep.VehicleManufacturer] = manufacturer;
			state.steps[Step.Start][SubStep.VehicleUnknown] = null;
		},
		[VehicleMutation.SetVehicle]: (state: IRootState, vehicle) => {
			state.steps[Step.Start][SubStep.Vehicle] = vehicle;
			state.steps[Step.Start][SubStep.VehicleUnknown] = null;
		},
		[VehicleMutation.SetVehicleUnknown]: (state: IRootState, vehicleUnknown) => {
			state.steps[Step.Start][SubStep.VehicleUnknown] = vehicleUnknown;
			state.steps[Step.Start][SubStep.VehicleManufacturer] = null;
			state.steps[Step.Start][SubStep.Vehicle] = null;
		},

		[PropertyMutation.SetProperty]: (state: IRootState, property) => {
			state.steps[Step.Property][SubStep.Property] = property;
		},
		[PropertyMutation.SetPropertyOwnership]: (state: IRootState, ownership) => {
			state.steps[Step.Property][SubStep.PropertyOwnership] = ownership;
		},

		[ParkingMutation.SetParking]: (state: IRootState, parking) => {
			state.steps[Step.Parking][SubStep.Parking] = parking;
		},
		[ParkingMutation.SetParkingOwnership]: (state: IRootState, ownership) => {
			state.steps[Step.Parking][SubStep.ParkingOwnership] = ownership;
		},

		[InstallationMutation.SetInstallation]: (state: IRootState, installation) => {
			state.steps[Step.Installation][SubStep.Installation] = installation;
		},

		[IntegrationMutation.SetIntegration]: (state: IRootState, integration: number[]) => {
			state.steps[Step.Integration][SubStep.Integration] = integration;
		},

		[ChargingStationMutation.SetChargingStationIntelligent]: (state: IRootState, intelligent: boolean) => {
			state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent] = intelligent;
		},
		[ChargingStationMutation.SetChargingStation]: (state: IRootState, chargingStationProduct: IApiChargingStationProduct) => {
			state.steps[Step.ChargingStation][SubStep.ChargingStation] = chargingStationProduct;
		},
		[ChargingStationMutation.SetChargingStationConnectivity]: (state: IRootState, connectivities: []) => {
			state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity] = connectivities;
		},
		[ChargingStationMutation.SetChargingStationSignalQualityWifi]: (state: IRootState, signalQualityWifi: number) => {
			state.steps[Step.ChargingStation][SubStep.ChargingStationSignalQualityWifi] = signalQualityWifi;
		},
		[ChargingStationMutation.SetChargingStationSignalQualityGprs]: (state: IRootState, signalQualityGprs: number) => {
			state.steps[Step.ChargingStation][SubStep.ChargingStationSignalQualityGprs] = signalQualityGprs;
		},

		[ExistingOutletsMutation.SetExistingOutletsAvailable]: (state: IRootState, existingOutletsAvailable: boolean) => {
			state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsAvailable] = existingOutletsAvailable;

			if(!existingOutletsAvailable)
			{
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutlets] = [];
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUsable] = false;
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUseExisting] = false;
			}
		},
		[ExistingOutletsMutation.SetExistingOutlets]: (state: IRootState, existingOutlets: number[]) => {
			state.steps[Step.ExistingOutlets][SubStep.ExistingOutlets] = existingOutlets;

			let usableOutlets = [];
			usableOutlets = existingOutlets.filter(function(value){
				return value >= Outlet.CEE16400;
			});

			if(usableOutlets.length > 0)
			{
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUsable] = true;
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUseExisting] = true;
			}
			else
			{
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUsable] = false;
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUseExisting] = false;
			}
		},
		[ExistingOutletsMutation.SetExistingOutletsUseExisting]: (state: IRootState, existingOutletsUseExisting: boolean) => {
			if(state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUsable])
			{
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUseExisting] = existingOutletsUseExisting;
			}
			else
			{
				state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsUseExisting] = false;
			}
		},

		[DistanceMutation.SetDistance]: (state: IRootState, distance: number) => {
			state.steps[Step.Distance][SubStep.Distance] = distance;
		},

		[WallsMutation.SetWallAmount]: (state: IRootState, wallAmount: number) => {
			state.steps[Step.Walls][SubStep.WallAmount] = wallAmount;
		},

		[FusePanelMutation.SetFusePanel]: (state: IRootState, fusePanel: number) => {
			state.steps[Step.FusePanel][SubStep.FusePanel] = fusePanel;
		},

		[ExistingChargerMutation.SetExistingChargerAvailable]: (state: IRootState, existingChargerAvailable: boolean) => {
			state.steps[Step.ExistingCharger][SubStep.ExistingChargerAvailable] = existingChargerAvailable;

			if(!existingChargerAvailable)
			{
				state.steps[Step.ExistingCharger][SubStep.ExistingChargerPower] = null;
				state.steps[Step.ExistingCharger][SubStep.ExistingChargerIntelligent] = null;
			}
		},
		[ExistingChargerMutation.SetExistingChargerPower]: (state: IRootState, existingChargerPower: number) => {
			state.steps[Step.ExistingCharger][SubStep.ExistingChargerPower] = existingChargerPower;
		},
		[ExistingChargerMutation.SetExistingChargerIntelligent]: (state: IRootState, existingChargerIntelligent: boolean) => {
			state.steps[Step.ExistingCharger][SubStep.ExistingChargerIntelligent] = existingChargerIntelligent;
		},

		[CalculationMutation.SetCalculation]: (state: IRootState, calculationData: object) => {
			Object.assign(state.steps[Step.Calculation][SubStep.Calculation], calculationData);
		},
		[CalculationMutation.SetCouponCode]: (state: IRootState, couponCode: string) => {
			state.steps[Step.Calculation][SubStep.CalculationSummary].couponCode = couponCode;

			if(!couponCode)
			{
				state.steps[Step.Calculation][SubStep.CouponDefinition] = null;
			}
		},
		[CalculationMutation.SetFurtherAction]: (state: IRootState, furtherAction: number) => {
			state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction = furtherAction;
		},

		[ContactDataMutation.SetResellerContactSelection]: (state: IRootState, contactSelection: number) => {
			state.steps[Step.ContactData][SubStep.ResellerContactSelection] = contactSelection;
		},
		[ContactDataMutation.SetInstallerContactSelection]: (state: IRootState, contactSelection: number) => {
			state.steps[Step.ContactData][SubStep.InstallerContactSelection] = contactSelection;
		},
		[ContactDataMutation.SetRemarks]: (state: IRootState, remarks: string) => {
			state.steps[Step.ContactData][SubStep.Remarks] = remarks;
		},
		[ContactDataMutation.SetContactData]: (state: IRootState, contactData: IApiContact) => {
			Object.assign(state.steps[Step.ContactData][SubStep.ContactData], contactData);
		},

		[HomeCheckAppointmentDateWishesMutation.SetHomeCheckAppointmentDateWishes]: (state: IRootState, dateWishes: IApiAppointmentDateWish[]) => {
			state.steps[Step.ContactData][SubStep.HomeCheckAppointmentDateWishes] = dateWishes;
		},

		[StepMutation.SetFiles]: (state: IRootState, data: {step: Route; files: File[]}) => {
			state.steps[data.step.meta.stepId].files = data.files;
		},
		[StepMutation.SetNotes]: (state: IRootState, data: {step: Route; notes: string}) => {
			state.steps[data.step.meta.stepId].notes = data.notes;
		},

		[APIMutation.SetAPIConfig]: (state: IRootState, config) => {
			state.api.config.priceGroupId = config.priceGroupId;
			state.api.config.includingVAT = config.includingVAT;
			state.api.config.currencyCode = config.currencyCode;
			state.api.config.taxRate = config.taxRate;
			state.api.config.countryId = config.countryId;
			state.api.config.countryCode = config.countryCode;
			state.api.config.showCouponCode = config.showCouponCode;
			state.api.config.homeCheckOrderInfoText = config.homeCheckOrderInfoText;
			state.api.config.showOptionUnknownVehicle = config.showOptionUnknownVehicle;
			state.api.config.showOptionExistingCharger = config.showOptionExistingCharger;
			state.api.config.showOptionHomeCheckOrder = config.showOptionHomeCheckOrder;
			state.api.config.showOptionResellerAutomaticSelection = config.showOptionResellerAutomaticSelection;
			state.api.config.showOptionContactApproachType = config.showOptionContactApproachType;
			state.api.config.vehicleManufacturers = <IApiVehicleManufacturer[]>config.vehicleManufacturers;
			state.api.config.vehicles = <IApiVehicle[]>config.vehicles;
			state.api.config.chargingStationProducts = <IApiChargingStationProduct[]>config.chargingStationProducts;
			state.api.config.calculationProducts = <IApiProduct[]>config.calculationProducts;
			state.api.config.supportContact = <IApiContact>config.supportContact;
			state.api.config.resellerContacts = <IApiContact[]>config.resellerContacts;
			state.api.config.installerContacts = <IApiContact[]>config.installerContacts;

			if(state.api.config.vehicleManufacturers.length === 1)
			{
				state.steps[Step.Start][SubStep.VehicleManufacturer] = state.api.config.vehicleManufacturers[0];
			}

		},
		[APIMutation.SetAPIPrices]: (state: IRootState, prices) => {
			state.api.prices.rrp = <IApiPrice[]>prices.rrp;
			state.api.prices.relative = <IApiPrice[]>prices.relative;
			state.api.prices.discount = <IApiPrice[]>prices.discount;
			state.api.prices.voucher = <IApiPrice[]>prices.voucher;
		},
		[APIMutation.SetAPICouponPrices]: (state: IRootState, prices) => {
			state.api.couponPrices.rrp = <IApiPrice[]>prices.rrp;
			state.api.couponPrices.relative = <IApiPrice[]>prices.relative;
			state.api.couponPrices.discount = <IApiPrice[]>prices.discount;
			state.api.couponPrices.voucher = <IApiPrice[]>prices.voucher;
		},
		[APIMutation.SetAPICouponDefinition]: (state: IRootState, couponDefinition) => {
			state.steps[Step.Calculation][SubStep.CouponDefinition] = couponDefinition;
		},
		[APIMutation.SetAPISalutations]: (state: IRootState, salutations) => {
			state.api.salutations = salutations;
		},
		[APIMutation.SetAPILoading]: (state: IRootState, loading: boolean) => {
			state.api.loading = loading;
		}
	},
	actions: {
		[AppAction.SwitchLocale]: async (context, locale: Locale) => {
			context.commit(AppMutation.SetLocale, locale);

			try
			{
				await context.dispatch(APIAction.LoadAll);
			}
			catch (e)
			{
				//todo: log
			}
		},

		[APIAction.LoadConfig]: async (context) => {
			//load process first
			const request = new AjaxRequest({
				url: `/dhc/${process.env.VUE_APP_DHC_CONFIG}`,
				params: {
					include: [
						'vehicleManufacturers.logo',
						'vehicles.image',
						'vehicles.connectorsAC.image',
						'vehicles.connectorsDC.image',
						'chargingStationProducts.chargingStation.image',
						'chargingStationProducts.pillar',
						'calculationProducts',
						'supportContact',
						'resellerContacts',
						'installerContacts',
					]
				}
			});

			const response = await request.send();

			if (response.wasSuccessful)
			{
				context.commit(APIMutation.SetAPIConfig, response.getData());
			}
		},
		[APIAction.LoadPrices]: async (context) => {
			//load process first
			const request = new AjaxRequest({
				baseURL: process.env.VUE_APP_SHOP_API_BASE,
				url: `/product-prices`,
				params: {
					fallbackPriceGroupId: context.state.api.config.priceGroupId,
					countryId: context.state.api.config.countryId,
					includingVAT: +context.state.api.config.includingVAT
				}
			});

			const response = await request.send();

			if (response.wasSuccessful)
			{
				context.commit(APIMutation.SetAPIPrices, response.getData());
			}
		},
		[APIAction.LoadCouponPrices]: async (context) => {
			//load process first
			const request = new AjaxRequest({
				baseURL: process.env.VUE_APP_SHOP_API_BASE,
				url: `/product-prices`,
				params: {
					fallbackPriceGroupId: context.state.steps[Step.Calculation][SubStep.CouponDefinition].priceGroup,
					countryId: context.state.api.config.countryId,
					includingVAT: +context.state.api.config.includingVAT
				}
			});

			const response = await request.send();

			if (response.wasSuccessful)
			{
				context.commit(APIMutation.SetAPICouponPrices, response.getData());
			}
		},
		[APIAction.ValidateCoupon]: async (context) => {
			const request = new AjaxRequest({
				baseURL: process.env.VUE_APP_API_BASE,
				url: `/dhc/${process.env.VUE_APP_DHC_CONFIG}/validate-coupon`,
				params: {
					code: context.state.steps[Step.Calculation][SubStep.CalculationSummary].couponCode
				}
			});

			const response = await request.send();

			if (response && response.wasSuccessful)
			{
				context.commit(APIMutation.SetAPICouponDefinition, response.getData());
				await context.dispatch(APIAction.LoadCouponPrices);
			}
			else
			{
				context.commit(APIMutation.SetAPICouponDefinition, null);
			}
		},
		[APIAction.LoadSalutations]: async (context) => {
			try
			{
				const request = new AjaxRequest({
					url: '/salutations'
				});

				const response = await request.send();

				if (response.wasSuccessful)
				{
					context.commit(APIMutation.SetAPISalutations, response.getData());
				}
			}
			catch (e)
			{
				//todo: log
			}
		},
		[APIAction.LoadAll]: async (context) => {

			//set loading
			context.commit(APIMutation.SetAPILoading, true);

			try
			{
				await context.dispatch(APIAction.LoadConfig);
				await context.dispatch(APIAction.LoadPrices);
				await context.dispatch(APIAction.LoadSalutations);
			}
			catch (e)
			{
				//console.log("API load error: " + e.message);
			}

			//set loading
			context.commit(APIMutation.SetAPILoading, false);
		},
		[APIAction.SubmitData]: async (context) => {
			const data = {
				steps: cloneDeep(context.state.steps)
			};

			const files = {
				[Step.Property]: context.state.steps[Step.Property].files,
				[Step.Parking]: context.state.steps[Step.Parking].files,
				[Step.Installation]: context.state.steps[Step.Installation].files,
				[Step.Integration]: context.state.steps[Step.Integration].files,
				[Step.ExistingOutlets]: context.state.steps[Step.ExistingOutlets].files,
				[Step.Distance]: context.state.steps[Step.Distance].files,
				[Step.Walls]: context.state.steps[Step.Walls].files,
				[Step.FusePanel]: context.state.steps[Step.FusePanel].files
			};

			const formData = new FormData();

			formData.append('data', JSON.stringify(data));

			for (const key of Object.keys(files))
			{
				const fileArray = files[key];

				for (const file of fileArray)
				{
					formData.append(`files[${key}][]`, file);
				}
			}

			//set loading
			context.commit(APIMutation.SetAPILoading, true);

			try
			{
				const request = new AjaxRequest({
					url: '/dhc/' + `${process.env.VUE_APP_DHC_CONFIG}`,
					method: 'POST',
					headers: {
						'Content-Type': 'multipart/form-data'
					},
					data: formData
				});

				const response = await request.send();

				if (response.wasSuccessful)
				{
					context.commit(AppMutation.SetSubmitted, true);

					return true;
				}

				return false;
			}
			catch (e)
			{
				return false;
			}
			finally {
				context.commit(APIMutation.SetAPILoading, false);
			}
		}
	}
});

	import Vue from 'vue';
	import Component from 'vue-class-component';
	import {ModalEvent} from '@/events';
	import {Prop} from 'vue-property-decorator';

	@Component({
		name: 'Modal'
	})
	export default class Modal extends Vue
	{
		@Prop({required: true})
		private id: string;

		@Prop()
		private width: number;

		@Prop({default: true})
		private backdrop: boolean;

		@Prop({default: true})
		private closable: boolean;

		private display = false;

		private mounted()
		{
			this.$on(ModalEvent.Hide, this.hide);
			this.$on(ModalEvent.Show, this.show);

			this.$modal.registerModal(this);
		}

		private hide(): void
		{
			this.display = false;
			this.$emit('hidden');
		}

		private show(): void
		{
			this.display = true;
			this.$emit('shown');
		}
	}

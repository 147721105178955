enum CalculationProduct
{
	HomeCheck = 'home-check',
	RemountCEE = 'remount-cee',
	InstallationApproval = 'installation-approval',
	TravelExpenses = 'travel-expenses',
	WallBreakthrough = 'wall-breakthrough',
	WallBreakthroughFireBulkhead = 'wall-breakthrough-fire-bulkhead',
	ProofOfSafety = 'proof-of-safety',
	UpdateLegends = 'update-legends',
	Cable = 'cable',
	PillarMounting = 'pillar-mounting',
	CbSwitch = 'cb-switch',
	RcdTypeA = 'rcd-type-a',
	RcdTypeAWithDc = 'rcd-type-a-with-dc',
	CommissioningDefault = 'commissioning-default',
	CommissioningSmart = 'commissioning-smart',
	SmallDistributor = 'small-distributor',
	BlockingContactor = 'clocking-contactor',
	WlanRepeater = 'wlan-repeater',
	GsmRepeater = 'gsm-repeater',
	PowerToLan = 'power-to-lan',
	ControlCable = 'control-cable'
}

export default CalculationProduct;
import Vue from 'vue';
import Router, {Route} from 'vue-router';
import ICustomRouteConfig from '@/router/interfaces/CustomRouteConfig';
import store from '@/store';
import StepService from '@/services/StepService';
import WelcomeSubStep from '@/components/Steps/Start/WelcomeSubStep.vue';
import ManufacturerSubStep from '@/components/Steps/Start/ManufacturerSubStep.vue';
import VehicleSubStep from '@/components/Steps/Start/VehicleSubStep.vue';
import PropertySubStep from '@/components/Steps/Property/PropertySubStep.vue';
import PropertyOwnershipSubStep from '@/components/Steps/Property/PropertyOwnershipSubStep.vue';
import ParkingSubStep from '@/components/Steps/Parking/ParkingSubStep.vue';
import ParkingOwnershipSubStep from '@/components/Steps/Parking/ParkingOwnershipSubStep.vue';
import ChargingStationIntelligentSubStep from '@/components/Steps/ChargingStation/ChargingStationIntelligentSubStep.vue';
import ChargingStationConnectivityAvailabilitySubStep from '@/components/Steps/ChargingStation/ChargingStationConnectivitySubStep.vue';
import InstallationSubStep from '@/components/Steps/Installation/InstallationSubStep.vue';
import HomeAutomationIntegrationSubStep from '@/components/Steps/Integration/IntegrationSubStep.vue';
import ExistingOutletsAvailableSubStep from '@/components/Steps/ExistingOutlets/ExistingOutletsAvailableSubStep.vue';
import ExistingOutletsSubStep from '@/components/Steps/ExistingOutlets/ExistingOutletsSubStep.vue';
import DistanceSubStep from '@/components/Steps/Distance/DistanceSubStep.vue';
import WallAmountSubStep from '@/components/Steps/Walls/WallAmountSubStep.vue';
import FusePanelSubStep from '@/components/Steps/FusePanel/FusePanelSubStep.vue';
import Step from '@/enums/Step';
import CalculationSubStep from '@/components/Steps/Calculation/CalculationSubStep.vue';
import ContactDataSubStep from '@/components/Steps/ContactData/ContactDataSubStep.vue';
import StartOverview from '@/components/StepOverview/StartOverview.vue';
import PropertyOverview from '@/components/StepOverview/PropertyOverview.vue';
import ParkingOverview from '@/components/StepOverview/ParkingOverview.vue';
import InstallationOverview from '@/components/StepOverview/InstallationOverview.vue';
import IntegrationOverview from '@/components/StepOverview/IntegrationOverview.vue';
import ChargingStationOverview from '@/components/StepOverview/ChargingStationOverview.vue';
import ExistingOutletsOverview from '@/components/StepOverview/ExistingOutletsOverview.vue';
import DistanceOverview from '@/components/StepOverview/DistanceOverview.vue';
import WallsOverview from '@/components/StepOverview/WallsOverview.vue';
import FusePanelOverview from '@/components/StepOverview/FusePanelOverview.vue';
import CalculationOverview from '@/components/StepOverview/CalculationOverview.vue';
import EventBus from '@/EventBus';
import {GlobalEvent} from '@/events';
import SubStep from '@/enums/SubStep';
import TranslationService from '@/services/TranslationService';
import ChargingStationSignalQualityWifiSubStep from '@/components/Steps/ChargingStation/ChargingStationSignalQualityWifiSubStep.vue';
import ChargingStationSignalQualityGprsSubStep from '@/components/Steps/ChargingStation/ChargingStationSignalQualityGprsSubStep.vue';
import {AppMutation} from '@/store/mutations';
import Locale from '@/enums/Locale';
import CalculationSummarySubStep from '@/components/Steps/Calculation/CalculationSummarySubStep.vue';
import ChargingStationService from "@/services/ChargingStationService";
import ChargingStationSubStep from "@/components/Steps/ChargingStation/ChargingStationSubStep.vue";

Vue.use(Router);

const routes: ICustomRouteConfig[] = [
	{
		path: '/',
		component: () => import(/* webpackChunkName: "start-view" */ '@/views/StartView.vue'),
		meta: {
			stepId: Step.Start,
			name: TranslationService.translate('Start'),
			faicon: ['far', 'play'],
			allowFiles: false,
			allowNotes: true,
			requiredSteps: [],
			subSteps: [
				{
					component: WelcomeSubStep,
					isSubStepFinished: () => {
						return true;
					},
					required: true
				},
				{
					component: ManufacturerSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Start][SubStep.VehicleManufacturer] || !!store.state.steps[Step.Start][SubStep.VehicleUnknown];
					},
					required: true,
					disabled: () => {
						return store.state.api.config.vehicleManufacturers.length === 1;
					},
				},
				{
					component: VehicleSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Start][SubStep.Vehicle] || !!store.state.steps[Step.Start][SubStep.VehicleUnknown];
					},
					requirements: {
						0: () => {
							return StepService.getStepById(Step.Start).meta.subSteps[1].isSubStepFinished();
						}
					},
					required: true
				}
			],
			overviewComponent: StartOverview
		}
	},
	{
		path: '/property',
		component: () => import(/* webpackChunkName: "property-view" */ '@/views/PropertyView.vue'),
		meta: {
			stepId: Step.Property,
			name: TranslationService.translate('Hosuing situation'),
			faicon: ['far', 'home'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start
			],
			subSteps: [
				{
					component: PropertySubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Property][SubStep.Property];
					},
					required: true
				},
				{
					component: PropertyOwnershipSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Property][SubStep.PropertyOwnership];
					},
					requirements: {
						0: () => {
							return StepService.getStepById(Step.Property).meta.subSteps[0].isSubStepFinished();
						}
					},
					required: true
				}
			],
			overviewComponent: PropertyOverview
		}
	},
	{
		path: '/parking',
		component: () => import(/* webpackChunkName: "parking-view" */ '@/views/ParkingView.vue'),
		meta: {
			stepId: Step.Parking,
			name: TranslationService.translate('Parking situation'),
			faicon: ['far', 'parking'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property
			],
			subSteps: [
				{
					component: ParkingSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Parking][SubStep.Parking];
					},
					required: true
				},
				{
					component: ParkingOwnershipSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Parking][SubStep.ParkingOwnership];
					},
					requirements: {
						0: () => {
							return StepService.getStepById(Step.Parking).meta.subSteps[0].isSubStepFinished();
						}
					},
					required: true
				}
			],
			overviewComponent: ParkingOverview
		}
	},
	{
		path: '/installation',
		component: () => import(/* webpackChunkName: "installation-view" */ '@/views/InstallationView.vue'),
		meta: {
			stepId: Step.Installation,
			name: TranslationService.translate('Installation'),
			faicon: ['far', 'wrench'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking
			],
			subSteps: [
				{
					component: InstallationSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Installation][SubStep.Installation];
					},
					required: true
				}
			],
			overviewComponent: InstallationOverview
		}
	},
	{
		path: '/integration',
		component: () => import(/* webpackChunkName: "integration-view" */ '@/views/IntegrationView.vue'),
		meta: {
			stepId: Step.Integration,
			name: TranslationService.translate('Integration'),
			faicon: ['far', 'solar-panel'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation
			],
			subSteps: [
				{
					component: HomeAutomationIntegrationSubStep,
					isSubStepFinished: () => {
						const integrations = store.state.steps[Step.Integration][SubStep.Integration];

						return Array.isArray(integrations) && !!integrations.length;
					},
					required: true
				}
			],
			overviewComponent: IntegrationOverview
		}
	},
	{
		path: '/charging-station',
		component: () => import(/* webpackChunkName: "charging-station-view" */ '@/views/ChargingStationView.vue'),
		meta: {
			stepId: Step.ChargingStation,
			name: TranslationService.translate('Charging station'),
			faicon: ['far', 'charging-station'],
			allowFiles: false,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.Integration
			],
			subSteps: [
				{
					component: ChargingStationIntelligentSubStep,
					isSubStepFinished: () => {
						return typeof store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent] === 'boolean';
					},
					required: true
				},
				{
					component: ChargingStationConnectivityAvailabilitySubStep,
					isSubStepFinished: () => {
						return store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent] === false || !!Object.keys(store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity]).length;
					},
					required: () => {
						return store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent] === true;
					},
					requirements: {
						0: () => {
							return store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent];
						}
					}
				},
				{
					component: ChargingStationSignalQualityWifiSubStep,
					isSubStepFinished: () => {
						const isConnectivityWifiSelected = store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(1);

						return !isConnectivityWifiSelected || typeof store.state.steps[Step.ChargingStation][SubStep.ChargingStationSignalQualityWifi] === 'number';
					},
					required: () => {
						return store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(1);
					},
					requirements: {
						0: () => {
							return store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(1);
						}
					}
				},
				{
					component: ChargingStationSignalQualityGprsSubStep,
					isSubStepFinished: () => {
						const isConnectivityGprsSelected = store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(3);

						return !isConnectivityGprsSelected || typeof store.state.steps[Step.ChargingStation][SubStep.ChargingStationSignalQualityGprs] === 'number';
					},
					required: () => {
						return store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(3);
					},
					requirements: {
						0: () => {
							return store.state.steps[Step.ChargingStation][SubStep.ChargingStationConnectivity].includes(3);
						}
					}
				},
				{
					component: ChargingStationSubStep,
					isSubStepFinished: () => {
						return ChargingStationService.getFilteredChargingStationProducts().length < 2 || store.state.steps[Step.ChargingStation][SubStep.ChargingStation] !== null;
					},
					required: () => {
						return ChargingStationService.getFilteredChargingStationProducts().length > 1;
					},
					requirements: {
						0: () => {
							return typeof store.state.steps[Step.ChargingStation][SubStep.ChargingStationIntelligent] === 'boolean';
						}
					},
					disabled: () => {
						return ChargingStationService.getFilteredChargingStationProducts().length === 1;
					}
				}
			],
			overviewComponent: ChargingStationOverview
		}
	},
	{
		path: '/existing-outlets',
		component: () => import(/* webpackChunkName: "existing-outlet-view" */ '@/views/ExistingOutletsView.vue'),
		meta: {
			stepId: Step.ExistingOutlets,
			name: TranslationService.translate('Existing outlets'),
			faicon: ['far', 'plug'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration
			],
			subSteps: [
				{
					component: ExistingOutletsAvailableSubStep,
					isSubStepFinished: () => {
						return typeof store.state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsAvailable] === 'boolean';
					},
					required: true
				},
				{
					component: ExistingOutletsSubStep,
					isSubStepFinished: () => {
						return store.state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsAvailable] === false || !!store.state.steps[Step.ExistingOutlets][SubStep.ExistingOutlets].length;
					},
					required: () => {
						return store.state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsAvailable] === true;
					},
					requirements: [
						() => { return store.state.steps[Step.ExistingOutlets][SubStep.ExistingOutletsAvailable]; }
					]
				}
			],
			overviewComponent: ExistingOutletsOverview
		}
	},
	{
		path: '/distance',
		component: () => import(/* webpackChunkName: "distance-view" */ '@/views/DistanceView.vue'),
		meta: {
			stepId: Step.Distance,
			name: TranslationService.translate('Distance'),
			faicon: ['far', 'ruler-horizontal'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration,
				Step.ExistingOutlets
			],
			subSteps: [
				{
					component: DistanceSubStep,
					isSubStepFinished: () => {
						return typeof store.state.steps[Step.Distance][SubStep.Distance] === 'number';
					},
					required: true
				}
			],
			overviewComponent: DistanceOverview
		}
	},
	{
		path: '/walls',
		component: () => import(/* webpackChunkName: "walls-view" */ '@/views/WallsView.vue'),
		meta: {
			stepId: Step.Walls,
			name: TranslationService.translate('Walls'),
			faicon: ['far', 'dungeon'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration,
				Step.ExistingOutlets,
				Step.Distance
			],
			subSteps: [
				{
					component: WallAmountSubStep,
					isSubStepFinished: () => {
						return typeof store.state.steps[Step.Walls][SubStep.WallAmount] === 'number';
					},
					required: true
				}
			],
			overviewComponent: WallsOverview
		}
	},
	{
		path: '/fuse-panel',
		component: () => import(/* webpackChunkName: "fuse-panel-view" */ '@/views/FusePanelView.vue'),
		meta: {
			stepId: Step.FusePanel,
			name: TranslationService.translate('Distribution box'),
			faicon: ['far', 'bolt'],
			allowFiles: true,
			allowNotes: true,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration,
				Step.ExistingOutlets,
				Step.Distance,
				Step.Walls
			],
			subSteps: [
				{
					component: FusePanelSubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.FusePanel][SubStep.FusePanel];
					},
					required: true
				}
			],
			overviewComponent: FusePanelOverview
		}
	},
	{
		path: '/calculation',
		component: () => import(/* webpackChunkName: "calculation-view" */ '@/views/CalculationView.vue'),
		meta: {
			stepId: Step.Calculation,
			name: TranslationService.translate('Calculation'),
			faicon: ['far', 'calculator'],
			allowFiles: false,
			allowNotes: false,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration,
				Step.ExistingOutlets,
				Step.Distance,
				Step.Walls,
				Step.FusePanel
			],
			subSteps: [
				{
					component: CalculationSubStep,
					isSubStepFinished: () => {
						return true;
					},
					required: true
				},
				{
					component: CalculationSummarySubStep,
					isSubStepFinished: () => {
						return !!store.state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction;
					},
					required: true
				}
			],
			overviewComponent: CalculationOverview
		}
	},
	{
		path: '/contact-data',
		component: () => import(/* webpackChunkName: "contact-data-view" */ '@/views/ContactDataView.vue'),
		meta: {
			stepId: Step.ContactData,
			name: TranslationService.translate('Contact data'),
			faicon: ['far', 'id-card-alt'],
			allowFiles: false,
			allowNotes: false,
			requiredSteps: [
				Step.Start,
				Step.Property,
				Step.Parking,
				Step.Installation,
				Step.ChargingStation,
				Step.Integration,
				Step.ExistingOutlets,
				Step.Distance,
				Step.Walls,
				Step.FusePanel,
				Step.Calculation
			],
			subSteps: [
				{
					component: ContactDataSubStep,
					isSubStepFinished: () => {
						const contactData = store.state.steps[Step.ContactData][SubStep.ContactData];

						if(contactData === null)
						{
							return false;
						}

						return !!contactData.salutation && !!contactData.country && !!contactData.lastName && !!contactData.email && !!contactData.mobile && !!contactData.postCode && !!contactData.city && !!contactData.address1;
					},
					required: true
				}
			]
		}
	}
];

//set all required steps to [] if in debug mode
if (store.state.debug === true)
{
	for (const route of routes)
	{
		route.meta.requiredSteps = [];
	}
}

const router = new Router({
	mode: 'history',
	routes
});

const setLocaleFromRouteQuery = (route: Route) => {
	const language = route.query.lang;

	if (language)
	{
		switch (language)
		{
			case 'de':
			{
				store.commit(AppMutation.SetLocale, Locale.German);
				break;
			}

			case 'fr':
			{
				store.commit(AppMutation.SetLocale, Locale.French);
				break;
			}

			case 'it':
			{
				store.commit(AppMutation.SetLocale, Locale.Italian);
				break;
			}

			case 'en':
			{
				store.commit(AppMutation.SetLocale, Locale.English);
				break;
			}
		}
	}
};

const setAssignmentContactIdFromRouteQuery = (route: Route) => {
	const contactId = route.query.contactId;

	if (contactId)
	{
		store.commit(AppMutation.SetPreSetAssignmentContactId, contactId);
	}
};

//step finished route guard
router.beforeEach((to, from, next) => {
	for (const requiredStepId of to.meta.requiredSteps)
	{
		if (!StepService.getIsStepFinished(StepService.getStepById(requiredStepId)))
		{
			return next(StepService.findHighestAvailableStep());
		}
	}

	if (StepService.getStepIndex(to) < StepService.getStepIndex(from))
	{
		StepService.slideTransition = 'slide-right';
	}
	else
	{
		StepService.slideTransition = 'slide-left';
	}

	next();
});

router.afterEach((to) => {
	EventBus.$emit(GlobalEvent.RouteChanged, to);

	setLocaleFromRouteQuery(to);
	setAssignmentContactIdFromRouteQuery(to);
});

export {routes};
export default router;
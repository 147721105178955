
	import Vue from 'vue';
	import Component from 'vue-class-component';

	@Component({
		name: 'Loader'
	})
	export default class Loader extends Vue
	{

	}

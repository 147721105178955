import ModalComponent from '@/components/Modal.vue';
import {ModalEvent} from '@/events';

class Modal
{
	private modals: Map<string,  ModalComponent> = new Map<string, ModalComponent>();

	public registerModal(modal: ModalComponent): void
	{
		this.modals.set(modal.$props['id'], modal);
	}

	public show(id: string)
	{
		if (this.modals.has(id))
		{
			this.modals.get(id).$emit(ModalEvent.Show);
		}
	}

	public hide(id: string)
	{
		if (this.modals.has(id))
		{
			this.modals.get(id).$emit(ModalEvent.Hide);
		}
	}
}

export default new Modal();

	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';

	@Component({
		name: 'DistanceOverview'
	})
	export default class DistanceOverview extends BaseStepOverview
	{
	}

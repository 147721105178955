
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import IInputOption from '@/components/Form/InputOption';
	import Step from '@/enums/Step';

	@Component({
		name: 'PropertyOverview'
	})
	export default class PropertyOverview extends BaseStepOverview
	{
		private get property(): IInputOption
		{
			return this.getSelectedOption(Step.Property, this.SubStep.Property);
		}

		private get ownership(): IInputOption
		{
			return this.getSelectedOption(Step.Property, this.SubStep.PropertyOwnership);
		}
	}

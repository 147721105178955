
	import Component from 'vue-class-component';
	import {AppMutation, CalculationMutation, ContactDataMutation, HomeCheckAppointmentDateWishesMutation} from '@/store/mutations';
	import SubStepComponent from '@/components/Steps/SubStep';
  import Step from '@/enums/Step';
  import {mapState} from "vuex";
  import {clone} from 'lodash-es';
  import SubStep from '@/enums/SubStep';
	import {ValidationObserver, ValidationProvider} from 'vee-validate';
  import {debounce, forEach} from 'lodash';
	import AjaxRequest from '@/axios/AjaxRequest';
	import FilterOperator from '@/axios/interfaces/FilterOperator';
	import {APIAction} from '@/store/actions';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import Modal from '@/components/Modal.vue';
	import CalculationTable from "@/components/Steps/Calculation/CalculationTable.vue";
	import Language from "@/enums/Language";
	import Locale from "@/enums/Locale";
  import IRootState, {IApiChargingStationProduct, IApiContact} from "@/store/interfaces/RootState";
  import FurtherActionEnum from "@/enums/FurtherAction";
  import Select2 from 'v-select2-component';
  import CalculationService from "@/services/CalculationService";
	import SwitchInput from '@/components/Form/SwitchInput.vue';
	import DatePicker from 'vue2-datepicker';
	import {IApiAppointmentDateWish} from '@/interfaces/ApiAppointmentDateWish';
	import 'vue2-datepicker/scss/index.scss';
	import {DateTime, Duration} from 'luxon';
	import 'vue2-datepicker/locale/de';
	import 'vue2-datepicker/locale/en';
	import 'vue2-datepicker/locale/fr';
	import 'vue2-datepicker/locale/it';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import {ValidationResult} from 'vee-validate/dist/types/types';
	import {CompanyService} from '@/services/company.service';
	import {ICompany} from '@/interfaces/company.interface';
	import {Watch} from 'vue-property-decorator';
	import {IBusinessPartner} from '@/interfaces/business-partner.interface';

	const defaultHomeCheckAppointmentDateWish: IApiAppointmentDateWish = {
		startDate: null,
		endDate: null,
		note: ''
	};

		@Component({
			name: 'ContactDataSubStep',
		computed: {
		  ...mapState({
			chargingStationProduct: (state: IRootState) => state.steps[Step.ChargingStation][SubStep.ChargingStation]
		  })
		},
		components: {
			DatePicker,
			SwitchInput,
			Modal,
			SmartCustomSelect,
			CalculationTable,
			ValidationObserver,
			ValidationProvider,
			Select2
		}
	})
	export default class ContactDataSubStep extends SubStepComponent
	{
    	private chargingStationProduct: IApiChargingStationProduct;
    	private contactData: IApiContact | null = null;

		private readonly FurtherActionEnum = FurtherActionEnum;

		private dataProtectionPolicyAccepted = false;
		private submitting = false;
		private hasCustomerHomeCheckDateWishes = false;
		private homeCheckAppointmentDateWish: IApiAppointmentDateWish = Object.assign({}, defaultHomeCheckAppointmentDateWish);
		private isEditingHomeCheckAppointmentDateWish = false;
		private originalHomeCheckAppointmentDateWish: IApiAppointmentDateWish | null = null;
		private homeCheckAppointmentDateWishes: IApiAppointmentDateWish[] = [];
		private companySuggestions: ICompany[] = [];
		private isLoadingCompanySuggestions: boolean = false;
		private showCompanySuggestions: boolean = false;
		private debouncedSearchCompanies = debounce(this.searchCompanies, 750);
		private businessPartners: IBusinessPartner[] = [];
		private segments: string[] = [];

		private debouncedCityLookup = debounce(this.lookupCity, 500);
		private citySuggestions = [];

		private furtherAction = null;
    private remarks = '';

		private selectedReseller = null;
		private selectedInstaller = null;

		private errorResellerContact = '';

    private calculationService: CalculationService = new CalculationService();

		private mounted()
		{
			this.contactData = Object.assign({}, this.$store.state.steps[Step.ContactData][SubStep.ContactData]);
			this.contactData.country = this.$store.state.api.config.countryId;

			this.furtherAction = this.$store.state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction;
			this.remarks = this.$store.state.steps[Step.ContactData][SubStep.Remarks];

			this.selectedReseller = this.$store.state.steps[Step.ContactData][SubStep.ResellerContactSelection];
			this.selectedInstaller = this.$store.state.steps[Step.ContactData][SubStep.InstallerContactSelection];
		}

		private async lookupCity(zipCode: number)
		{
			if (zipCode && zipCode > 999 && this.contactData.country)
			{
				try
				{
					const request = new AjaxRequest({
						url: '/cities',
						params: {
							search: zipCode,
							filters: {
								country: {
									0: {operator: FilterOperator.Equal, value: this.contactData.country}
								}
							}
						}
					});

					const response = await request.send();

					if (response.wasSuccessful)
					{
						this.citySuggestions = response.getData();
					}
				}
				catch (e)
				{
					//todo: log
				}
			}
			else
			{
				this.citySuggestions = [];
			}
		}

		private showOptionContactApproachType(): boolean
    {
      const furtherAction = this.$store.state.steps[Step.Calculation][SubStep.CalculationSummary].furtherAction;

      return this.$store.state.api.config.showOptionContactApproachType &&
          (furtherAction == FurtherActionEnum.ContactRequestedReseller ||
          furtherAction == FurtherActionEnum.ContactRequestedInstaller);
    }

		private get datePickerLanguage(): string
		{
			switch (this.$store.state.locale)
			{
				case Locale.German:
				{
					return 'de';
				}

				case Locale.French:
				{
					return 'fr';
				}

				case Locale.Italian:
				{
					return 'it';
				}

				default:
				{
					return 'en';
				}
			}
		}

		/**
		 * @param date
		 *
		 * @return True if the date should be disabled, false otherwise
		 */
		private homeCheckAppointmentDateWishStartDateDisabled(date: Date): boolean
		{
			const dateConverted = DateTime.fromJSDate(date);

			if (this.isEarlierThanThreeDaysAhead(dateConverted))
			{
				//disable if date is earlier than 3 days ahead
				return true;
			}

			if ([6, 7].includes(dateConverted.weekday))
			{
				//disable if date is on the weekend (6 = saturday, 7 = sunday)
				return true;
			}
		}

		private isEarlierThanThreeDaysAhead(date: DateTime): boolean
		{
			const now = DateTime.now();
			const threeDaysAhead = now.plus(Duration.fromObject({days: 3}));

			return date.startOf('day') < threeDaysAhead.startOf('day');
		}

		private onEditHomeCheckAppointmentDateWishClicked(homeCheckAppointmentDateWish: IApiAppointmentDateWish): void
		{
			this.homeCheckAppointmentDateWish = homeCheckAppointmentDateWish;
			this.originalHomeCheckAppointmentDateWish = Object.assign({}, homeCheckAppointmentDateWish);
			this.isEditingHomeCheckAppointmentDateWish = true;
		}

		private onDeleteHomeCheckAppointmentDateWishClicked(homeCheckAppointmentDateWish: IApiAppointmentDateWish): void
		{
			if (confirm('Are you sure you want to delete this date wish?'))
			{
				const index = this.homeCheckAppointmentDateWishes.findIndex(dateWish => dateWish.startDate.equals(homeCheckAppointmentDateWish.startDate));

				if (index >= 0)
				{
					this.homeCheckAppointmentDateWishes.splice(index, 1);
				}
			}
		}

    private showOptionResellerAutomaticSelection(): boolean
    {
      return this.$store.state.api.config.showOptionResellerAutomaticSelection;
    }

    private contactOutput(contact: IApiContact): string
    {
      return contact.company + ', ' + contact.address1 + ', ' + contact.postCode + ' ' + contact.city;
    }

    private getResellerContacts(): IApiContact[]
    {
      if(!this.$store.state.api.config.resellerContacts)
      {
        return [];
      }

      if(this.$store.state.preSetAssignmentContactId)
      {
        let allowedAssignmentContacts = [];
        const preSetAssignmentContactId = this.$store.state.preSetAssignmentContactId;

        allowedAssignmentContacts = this.$store.state.api.config.resellerContacts.filter(function(contact: IApiContact) {
          return preSetAssignmentContactId == contact.id;
        });

        if(allowedAssignmentContacts.length === 1)
        {
          this.$store.commit(ContactDataMutation.SetResellerContactSelection, preSetAssignmentContactId);
          return allowedAssignmentContacts;
        }
      }

      return this.$store.state.api.config.resellerContacts;
    }

    private getResellerOptions()
    {
      const options = [];

      forEach(this.getResellerContacts(), (contact: IApiContact) => {
        options.push({id: contact.id, text: this.contactOutput(contact)});
      });

      return options;
    }

    private getInstallerContacts(): IApiContact[]
    {
      if(!this.$store.state.api.config.installerContacts)
      {
        return [];
      }

      return this.$store.state.api.config.installerContacts;
    }

    private getInstallerOptions()
    {
      const options = [];

      forEach(this.getInstallerContacts(), (contact: IApiContact) => {
        options.push({id: contact.id, text: this.contactOutput(contact)});
      });

      return options;
    }

    private onContactDataChanged()
    {
      this.$store.commit(ContactDataMutation.SetContactData, this.contactData);
    }

		private onZipCodeChanged(event)
		{
			this.debouncedCityLookup(event.target.value);
		}

		private onCitySuggestionSelected(suggestion)
		{
			this.contactData.city = suggestion.name;
			this.citySuggestions = [];

      this.onContactDataChanged();
		}

		private onRemarksChanged(event)
    {
      this.remarks = event.target.value;
      this.$store.commit(ContactDataMutation.SetRemarks, event.target.value);
    }

		private onResellerContactSelectionChanged(id)
		{
      if(!id && !this.showOptionResellerAutomaticSelection())
      {
        this.errorResellerContact = 'This is a mandatory field';
      }
      else
      {
        this.errorResellerContact = '';
      }

			this.$store.commit(ContactDataMutation.SetResellerContactSelection, id);
		}

    private onInstallerContactSelectionChanged(id)
    {
      this.$store.commit(ContactDataMutation.SetInstallerContactSelection, id);
    }

		private onHomeCheckAppointmentDateWishStartDateChange(date: Date, type: 'date' | 'hour' | 'minute' | 'second' | 'ampm'): void
		{
			if (type === 'date')
			{
				let dateConverted = DateTime.fromJSDate(date);
				dateConverted = dateConverted.set({hour: DateTime.now().hour});

				this.homeCheckAppointmentDateWish.startDate = dateConverted;
			}
		}

		private onDateWishStartDateInput(startDate: Date)
		{
			const startDateConverted = DateTime.fromJSDate(startDate);

			this.homeCheckAppointmentDateWish.startDate = startDateConverted;

			if (startDate)
			{
				let endDateConverted = clone(startDateConverted);
				endDateConverted = endDateConverted.set({hour: startDateConverted.hour + 1});

				this.homeCheckAppointmentDateWish.endDate = endDateConverted;
			}
			else
			{
				this.homeCheckAppointmentDateWish.endDate = null;
			}
		}

		private onAddAppointmentDateWishClicked(): void
		{
			if (!this.homeCheckAppointmentDateWish.startDate) return;

			this.homeCheckAppointmentDateWishes.push(this.homeCheckAppointmentDateWish);

			//reset appointment date wish
			this.homeCheckAppointmentDateWish = Object.assign({}, defaultHomeCheckAppointmentDateWish);
		}

		private onEditHomeCheckAppointmentDateWishSaveClicked(): void
		{
			this.isEditingHomeCheckAppointmentDateWish = false;

			//reset appointment date wish
			this.homeCheckAppointmentDateWish = Object.assign({}, defaultHomeCheckAppointmentDateWish);
			this.originalHomeCheckAppointmentDateWish = null;
		}

		private onEditHomeCheckAppointmentDateWishCancelClicked(): void
		{
			this.isEditingHomeCheckAppointmentDateWish = false;

			//reset edited dated wish to origjnal data
			Object.assign(this.homeCheckAppointmentDateWish, this.originalHomeCheckAppointmentDateWish);

			//reset appointment date wish
			this.homeCheckAppointmentDateWish = Object.assign({}, defaultHomeCheckAppointmentDateWish);
			this.originalHomeCheckAppointmentDateWish = null;
		}

    private onFurtherActionContactTypeChanged(event)
    {
      this.furtherAction = event.target.value;

      //do not commit empty value to store, it would remove the visibility of the select field
      if(!event.target.value)
      {
        return;
      }

      this.$store.commit(CalculationMutation.SetFurtherAction, event.target.value);
    }

		private async onSubmitDataClicked(validate: () => Promise<ValidationResult>)
		{
			if(this.getResellerContacts().length > 0 &&
          !this.$store.state.steps[Step.ContactData][SubStep.ResellerContactSelection] &&
          !this.showOptionResellerAutomaticSelection())
      {
        this.errorResellerContact = 'This is a mandatory field';
        return;
      }

			try
			{
				const valid = await validate();

				if (!valid)
				{
					this.$modal.show('error-modal');

					return;
				}

				//prevent submission if it was already submitted
				if (this.$store.state.submitted) return;

				this.submitting = true;

				//set contact language from current locale, default English
				let language = Language.English;

				switch(this.$store.state.locale)
				{
					case Locale.German:
						language = Language.German;
						break;
					case Locale.French:
						language = Language.French;
						break;
					case Locale.Italian:
						language = Language.Italian;
						break;
					case Locale.English:
						language = Language.English;
						break;
				}

				this.contactData.language = language;

				this.$store.commit(ContactDataMutation.SetContactData, this.contactData);

				this.$store.commit(HomeCheckAppointmentDateWishesMutation.SetHomeCheckAppointmentDateWishes, this.homeCheckAppointmentDateWishes);

				this.$store.commit(CalculationMutation.SetCalculation, {
					"homeCheck": this.calculationService.getHomeCheckCost(),
					"installation": this.calculationService.getInstallationCost(),
					"chargingStation": this.calculationService.getChargerCost(this.chargingStationProduct.chargingStation),
					"pillar": this.calculationService.getPillarCost(this.chargingStationProduct.pillar),
					"shipping": this.calculationService.getShippingCost(this.chargingStationProduct),
					"discount": this.calculationService.getCouponCost(this.chargingStationProduct.chargingStation, this.chargingStationProduct.pillar),
					"hcDiscount": this.calculationService.getHomeCheckCouponCost(),
				});

				const success: boolean = await this.$store.dispatch(APIAction.SubmitData);

				if(success)
				{
					this.$modal.show('success-modal');
				}
				else
				{
					this.$modal.show('error-modal');
				}
			}
			finally {
				this.submitting = false;
			}
		}

		private onModalHomeClicked()
		{
			this.$modal.hide('success-modal');

			this.$store.commit(AppMutation.ResetStepData);
			this.$store.commit(AppMutation.SetSubmitted, false);

			this.$router.push('/');
		}

		private onModalOkClicked()
		{
			this.$modal.hide('error-modal');

			this.$store.commit(AppMutation.SetSubmitted, false);
		}

		//region company selection
		private async searchCompanies(companyName: string): Promise<void>
		{
			this.isLoadingCompanySuggestions = true;

			this.companySuggestions = await CompanyService.getCompanies(companyName, {code: 'ch'});

			this.isLoadingCompanySuggestions = false;
		}

		private onCompanyInput(event: any): void
		{
			//if user checked companyNotRegistered, we do nothing
			if (this.contactData.companyNotRegistered) return;

			const company = event.target.value;

			if (company.length > 0)
			{
				this.showCompanySuggestions = true;
			}
			else
			{
				this.showCompanySuggestions = false;
			}

			this.companySuggestions = [];
			this.segments = [];

			this.contactData.uid = null;
			this.contactData.segment = null;

			if (company.trim().length >= 3)
			{
				this.debouncedSearchCompanies(company);
			}
		}

		private onCompanyNotRegisteredChanged(companyNotRegistered: boolean): void
		{
			if (companyNotRegistered)
			{
				//reset uid and segment if companyNotRegistered changes to true (for example, if the user has selected a company, then toggles the switch)
				this.contactData.uid = null;
				this.contactData.segment = null;
				this.segments = [];
			}
		}

		private onCompanySuggestionSelected(companySuggestion: ICompany): void
		{
			this.showCompanySuggestions = false;
			this.companySuggestions = [];

			this.contactData.company = companySuggestion.name;
			this.contactData.uid = companySuggestion.uid;
			this.contactData.companyNotRegistered = false;

			this.loadBusinessPartnersByUid(companySuggestion.uid);
		}

		private async loadBusinessPartnersByUid(uid: string): Promise<void>
		{
			this.businessPartners = await CompanyService.getSapBusinessPartnerByCompanyUid(uid);

			//for now only consider bkw companies
			//considering other companies will be a separate feature in the future
			this.businessPartners = this.businessPartners.filter(businessPartner => businessPartner.isBkwCompany);

			for (const businessPartner of this.businessPartners)
			{
				if (businessPartner.segment)
				{
					this.segments.push(businessPartner.nameAddon);
				}
			}

			if (this.businessPartners.length === 1)
			{
				//if there is exactly one business partner, select it and add its sapBusinessPartnerId to the contact
				this.contactData.sapBusinessPartnerId = this.businessPartners[0].sapBusinessPartnerId;
			}
		}

		private onSegmentInput(event: any): void
		{
			const segment = event.target.value;
			const businessPartner = this.businessPartners.find(searchBusinessPartner => searchBusinessPartner.nameAddon === segment);

			if (businessPartner)
			{
				this.contactData.sapBusinessPartnerId = businessPartner.sapBusinessPartnerId;
			}
		}
		//endregion
	}

import qs from 'qs';
import store from '@/store';
import axios, {AxiosRequestConfig} from 'axios';
import AjaxResponse from '@/axios/AjaxResponse';
import {merge} from 'lodash';

class AjaxRequest
{
	private readonly config: AxiosRequestConfig = {};

	constructor(customConfig: AxiosRequestConfig)
	{
		this.config.method = 'GET';
		this.config.baseURL = customConfig.baseURL || process.env.VUE_APP_API_BASE;
		this.config.timeout = 60000;
		this.config.params = {};

		this.config.paramsSerializer = (params): string => {
			return qs.stringify(params, {
				arrayFormat: 'indices',
				encode: true
			});
		};

		this.config = merge(this.config, customConfig);
		this.config.params.lang = this.config.params.lang || store.state.locale;
	}

	public async send(): Promise<AjaxResponse>
	{
		try
		{
			const response = await axios.request(this.config);

			return new AjaxResponse(response);
		}
		catch (e)
		{
			//todo: log
		}
	}
}

export default AjaxRequest;

	import Vue from 'vue';
	import Component from 'vue-class-component';
	import Navigation from '@/components/Navigation.vue';
	import {APIAction, AppAction} from '@/store/actions';
	import EventBus from '@/EventBus';
	import StepService from '@/services/StepService';
	import {GlobalEvent} from '@/events';
	import Sidebar from '@/components/Sidebar.vue';
	import CountryFlag from 'vue-country-flag';
	import Locale from '@/enums/Locale';
	import {AppMutation} from '@/store/mutations';
	import Loader from "@/components/Loader.vue";

	@Component({
		components: {Loader, Sidebar, Navigation, CountryFlag}
	})
	export default class App extends Vue
	{
		private readonly StepService = StepService;
		private readonly Locale = Locale;
		private readonly version = process.env.APP_VERSION;

		private showNextControl = false;

		private mounted()
		{
			document.title = process.env.VUE_APP_DHC_NAME + ' - Digital Home Check';

			EventBus.$on(GlobalEvent.PreviousStep, () => StepService.previous());
			EventBus.$on(GlobalEvent.NextStep, () => StepService.next());

			EventBus.$on(GlobalEvent.TranslationsLoaded, () => {
				if (!this.$store.state.locale)
				{
					//set default locale
					this.$store.commit(AppMutation.SetLocale, Locale.German);
				}
			});

			this.$store.dispatch(APIAction.LoadAll);
		}

		private onSwitchLanguageClicked(locale: Locale)
		{
			this.$store.dispatch(AppAction.SwitchLocale, locale);
		}
	}

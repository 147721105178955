
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import WallPenetrationImage from '@/assets/img/wall-penetration.png';

	@Component({
		name: 'WallsOverview'
	})
	export default class WallsOverview extends BaseStepOverview
	{
		private WallPenetrationImage = WallPenetrationImage;

	}

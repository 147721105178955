import {AxiosResponse} from 'axios';

class AjaxResponse
{
	private axiosResponse: AxiosResponse;

	constructor(axiosReponse: AxiosResponse)
	{
		this.axiosResponse = axiosReponse;
	}

	public get wasSuccessful(): boolean
	{
		return this.axiosResponse.status === 200;
	}

	public getData()
	{
		return this.axiosResponse.data.data;
	}
}

export default AjaxResponse;
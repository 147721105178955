
	import Component from 'vue-class-component';
	import BaseStepOverview from '@/components/StepOverview/BaseStepOverview';
	import IInputOption from '@/components/Form/InputOption';

	@Component({
		name: 'IntegrationOverview'
	})
	export default class IntegrationOverview extends BaseStepOverview
	{
		private get integrations(): IInputOption[]
		{
			return this.getSelectedOptions(this.Step.Integration, this.SubStep.Integration);
		}
	}


	import Component from 'vue-class-component';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import SwitchInput from '@/components/Form/SwitchInput.vue';
	import {ChargingStationMutation} from '@/store/mutations';
	import SubStep from '@/components/Steps/SubStep';
  import ChargingStationService from "@/services/ChargingStationService";

	@Component({
		name: 'ChargingStationIntelligentSubStep',
		components: {SwitchInput, SmartCustomSelect}
	})
	export default class ChargingStationIntelligentSubStep extends SubStep
	{
		private onChargingStationIntelligentSelected(value: boolean)
		{
			this.$store.commit(ChargingStationMutation.SetChargingStationIntelligent, value);

			//reset charging station selection
      this.$store.commit(ChargingStationMutation.SetChargingStation, null);

			if (value === true && ChargingStationService.getFilteredChargingStationProducts().length == 1)
			{
        this.$store.commit(ChargingStationMutation.SetChargingStation, ChargingStationService.getFilteredChargingStationProducts()[0]);
				this.$emit('next-sub-step');
			}
			else if (value === false && ChargingStationService.getFilteredChargingStationProducts().length == 1)
			{
        this.$store.commit(ChargingStationMutation.SetChargingStation, ChargingStationService.getFilteredChargingStationProducts()[0]);
				this.$emit('next-step');
			}
      else if (value === false && ChargingStationService.getFilteredChargingStationProducts().length > 1)
      {
        this.$emit('next-sub-step');
      }
		}
	}

import store from '@/store';
import {IApiPrice, IApiProduct, IPrice} from "@/store/interfaces/RootState";

class PriceService
{
	public getPriceOfProduct(product: IApiProduct): IPrice
	{
		const productPrice: IPrice = {
			price: null,
			couponPrice: null
		};

		const prices = store.state.api.prices;
		const couponPrices = store.state.api.couponPrices;

		const priceRrp = this.findApiPriceOfProduct(prices.rrp, product);
		const priceRelative = this.findApiPriceOfProduct(prices.relative, product);
		const priceDiscount = this.findApiPriceOfProduct(prices.discount, product);
		const priceVoucher = this.findApiPriceOfProduct(prices.voucher, product);

		productPrice.price = (priceRrp) ? priceRrp.price : null;
		productPrice.price = (priceRelative) ? priceRelative.price : productPrice.price;
		productPrice.price = (priceDiscount) ? priceDiscount.price : productPrice.price;
		productPrice.price = (priceVoucher) ? priceVoucher.price : productPrice.price;

		const couponPriceRrp = this.findApiPriceOfProduct(couponPrices.rrp, product);
		const couponPriceRelative = this.findApiPriceOfProduct(couponPrices.relative, product);
		const couponPriceDiscount = this.findApiPriceOfProduct(couponPrices.discount, product);
		const couponPriceVoucher = this.findApiPriceOfProduct(couponPrices.voucher, product);

		productPrice.couponPrice = (couponPriceRrp) ? couponPriceRrp.price : null;
		productPrice.couponPrice = (couponPriceRelative) ? couponPriceRelative.price : productPrice.couponPrice;
		productPrice.couponPrice = (couponPriceDiscount) ? couponPriceDiscount.price : productPrice.couponPrice;
		productPrice.couponPrice = (couponPriceVoucher) ? couponPriceVoucher.price : productPrice.couponPrice;

		return productPrice;
	}

	private findApiPriceOfProduct(prices: IApiPrice[], product: IApiProduct): IApiPrice
	{
		return prices.find((apiPrice: IApiPrice) => {
			if (apiPrice.product === product.id)
			{
				return apiPrice.price >= 0;
			}

			return false;
		});
	}

}

export default PriceService;

	import Component from 'vue-class-component';
	import SubStep from '@/components/Steps/SubStep';
	import SmartCustomSelect from '@/components/Form/SmartCustomSelect.vue';
	import {ChargingStationMutation} from '@/store/mutations';

	@Component({
		name: 'ChargingStationSignalQualityWifiSubStep',
		components: {SmartCustomSelect}
	})
	export default class ChargingStationSignalQualityWifiSubStep extends SubStep
	{
		private onSignalQualitySelected(signalQuality: number)
		{
			this.$store.commit(ChargingStationMutation.SetChargingStationSignalQualityWifi, signalQuality);

			if (signalQuality)
			{
				this.$emit('next-sub-step');
			}
		}
	}


	import Vue from 'vue';
	import Component from 'vue-class-component';
	import EventBus from '@/EventBus';
	import {GlobalEvent} from '@/events';
	import {StepMutation} from '@/store/mutations';
	import ICustomRouteConfig from '@/router/interfaces/CustomRouteConfig';

	@Component({
		name: 'Notes'
	})
	export default class Notes extends Vue
	{
		private visible = false;
		private notes = null;

		private mounted()
		{
			EventBus.$on(GlobalEvent.LoadNotes, this.onLoadNotes);
			EventBus.$on(GlobalEvent.SaveNotes, this.onSaveNotes);
		}

		private onLoadNotes(step: ICustomRouteConfig)
		{
			if (!step.meta.allowNotes) return;

			this.notes = this.$store.state.steps[step.meta.stepId].notes;
		}

		private onSaveNotes(step: ICustomRouteConfig)
		{
			if (!step.meta.allowNotes) return;

			this.$store.commit(StepMutation.SetNotes, {step, notes: this.notes});
			this.notes = null;
		}
	}

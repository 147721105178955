import Vue from 'vue';
import Component from 'vue-class-component';
import StepOptions, {getSelectedOption, getSelectedOptions} from '@/step-options';
import Step from '@/enums/Step';
import SubStep from '@/enums/SubStep';

//@ts-ignore
@Component
abstract class BaseStepOverview extends Vue
{
	protected readonly StepOptions = StepOptions;
	protected readonly Step = Step;
	protected readonly SubStep = SubStep;
	protected readonly getSelectedOption = getSelectedOption;
	protected readonly getSelectedOptions = getSelectedOptions;
}

export default BaseStepOverview;